import { type Advisor, VERDE_WHATSAPP_LINK } from "@verde/entities";
import { ExternalLinkIcon, WhatsappIcon } from "@verde/icons";
import { useAuthContext } from "@verde/modules";
import { IconButton, Menu } from "@verde/ui-core";
import { FloatingProvider } from "@verde/utils";
import { memo, useMemo } from "react";
import { useLayoutDefault } from ".";
import type { FloatingProps } from "./types";

function Floating({ children }: FloatingProps) {
  const { handleOpenFaq } = useLayoutDefault();
  const { user } = useAuthContext<Advisor>();

  const makeItems = useMemo(() => {
    let items = [
      {
        text: "Iniciar chat",
        icon: <WhatsappIcon />,
        onClick: () => {
          window.open(
            user?.kind === "SAAS_USER" ? VERDE_WHATSAPP_LINK : "https://wa.me",
            "_blank",
          );
        },
      },
    ];

    if (user?.kind !== "SAAS_USER") {
      items = [
        ...items,
        {
          text: "Dúvidas frequentes",
          icon: <ExternalLinkIcon />,
          onClick: handleOpenFaq,
        },
      ];
    }

    return items;
  }, [user, handleOpenFaq]);

  return (
    <FloatingProvider
      defaultElements={[
        {
          id: "chat",
          children: (
            <Menu align="start" items={makeItems}>
              <IconButton
                size="2xl"
                className="border-4 border-brand-yellow text-brand-yellow hover:bg-brand-yellow hover:text-neutral-light-pure"
              >
                <span className="text-[32px] font-bold leading-[0.8]">?</span>
              </IconButton>
            </Menu>
          ),
        },
      ]}
    >
      {children}
    </FloatingProvider>
  );
}

export default memo(Floating);
