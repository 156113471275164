function path<Root extends string, Sublink extends string>(
  root: Root,
  sublink: Sublink,
): `${Root}${Sublink}` {
  return `${root}${sublink}`;
}

const ROOTS = {
  app: "/",
  auth: "/auth",
} as const;

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, "/login"),
    resetPassword: path(ROOTS.auth, "/reset-password"),
    changePassword: path(ROOTS.auth, "/change-password/:token"),
    firstAccess: path(ROOTS.auth, "/first-access/:token"),
    introduction: path(ROOTS.auth, "/introduction"),
    signup: path(ROOTS.auth, "/signup"),
  },
} as const;

export const PATH_APP = {
  root: ROOTS.app,
  onboarding: {
    root: path(ROOTS.app, "introducao"),
    appointment: path(ROOTS.app, "introducao/agendamento"),
  },
  general: {
    root: path(ROOTS.app, "dashboard"),
    dashboard: path(ROOTS.app, "dashboard"),
  },
  simulador: {
    root: path(ROOTS.app, "simulador"),
  },
  clients: {
    default: {
      root: path(ROOTS.app, "clientes"),
      details: path(ROOTS.app, "clientes/:id"),
      basicData: path(ROOTS.app, "clientes/:id/dados-basicos"),
      productions: path(ROOTS.app, "clientes/:id/producoes"),
      properties: path(ROOTS.app, "clientes/:id/propriedades"),
      productionPartners: path(ROOTS.app, "clientes/:id/participantes"),
      documents: path(ROOTS.app, "clientes/:id/documentos"),
    },
    saas: { root: path(ROOTS.app, "clientes") },
  },
  proposals: {
    root: path(ROOTS.app, "propostas"),
    details: path(ROOTS.app, "propostas/:id"),
    productions: path(ROOTS.app, "propostas/:id/producoes"),
    properties: path(ROOTS.app, "propostas/:id/propriedades"),
    productionPartners: path(ROOTS.app, "propostas/:id/participantes"),
    warranties: path(ROOTS.app, "propostas/:id/garantias"),
  },
  account: {
    root: path(ROOTS.app, "minha-conta"),
  },
  workflowProposal: {
    root: path(ROOTS.app, "proposta"),
    financing: path(ROOTS.app, "proposta/:id/financiamento"),
    incomeSources: path(ROOTS.app, "proposta/:id/fontes-renda"),
    people: path(ROOTS.app, "proposta/:id/participantes"),
    checkout: path(ROOTS.app, "proposta/:id/checkout"),
    resume: path(ROOTS.app, "proposta/:id/resumo"),
  },
  workflowContract: {
    cliente: path(ROOTS.app, "contrato/:id/cliente"),
    areaFraming: path(ROOTS.app, "contrato/:id/enquadramento"),
    production: path(ROOTS.app, "contrato/:id/producao"),
    warranties: path(ROOTS.app, "contrato/:id/garantias"),
    resume: path(ROOTS.app, "contrato/:id/resumo"),
  },
  workflowClient: {
    search: path(ROOTS.app, "clientes/cadastro"),
    qualification: path(ROOTS.app, "clientes/:id/qualificacao"),
  },
  raioXrural: {
    search: path(ROOTS.app, "raio-x-rural"),
    details: path(ROOTS.app, "raio-x-rural/:client_id"),
  },
} as const;
