import { Dialog, type DialogProps } from "@verde/compose-ui";
import {
  AlertCircleIcon,
  CheckIcon,
  ExternalLinkIcon,
  FlagIcon,
} from "@verde/icons";
import { Alert, Link } from "@verde/ui-core";
import { createElement, memo } from "react";
import { useNavigate } from "react-router-dom";

import { useFetchFourthMissionInfo } from "@verde/entities";
import { useAuthContext } from "@verde/modules";
import { PATH_APP } from "routes/paths";
import { ProposalContractMissionCard } from ".";
import { useMissionsContext } from "../context";

interface ProposalContractDialogProps extends Pick<DialogProps, "open"> {}

function ProposalContractDialog({ open }: ProposalContractDialogProps) {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { closeMissionDialog, missionDialog } = useMissionsContext();

  const mission = useFetchFourthMissionInfo(user?.id);

  return (
    <Dialog
      open={open}
      size="sm"
      header={{
        title: (
          <div className="flex items-center gap-2 text-brand-green-dark">
            {createElement(FlagIcon, {
              className: "w-6 h-6",
            })}

            <h2 className="text-2xl font-semibold">
              Mais próximo do desembolso!
            </h2>
          </div>
        ),
        kind: "success",
      }}
      footer={{
        secondary: {
          children: "Fechar",
          onClick: closeMissionDialog,
        },
        primary:
          mission.data && mission.data?.proposals_count > 0
            ? {
                children: "Ver condições",
                onClick: () => {
                  navigate(PATH_APP.proposals.root, {
                    state: { by_status: "CONTRACT", by_step: "formalization" },
                  });

                  closeMissionDialog();
                },
                icon: <ExternalLinkIcon />,
              }
            : undefined,
        children: (
          <>
            {mission.data && mission.data?.proposals_count_confirmation > 0 && (
              <Alert kind="warning">
                <div className="flex items-center gap-4">
                  <AlertCircleIcon className="h-6 w-6" />
                  <p>
                    Ainda há propostas aprovadas sem confirmação! Não perca o
                    prazo e confirme junto aos seus clientes o quanto antes!
                  </p>
                </div>
              </Alert>
            )}
          </>
        ),
      }}
      classNames={{
        body: "flex flex-col gap-6",
      }}
    >
      <div className="flex items-center gap-2">
        <CheckIcon className="h-5 w-5 text-brand-green-dark" />

        <p className="text-xl font-bold text-brand-green-dark">
          Dados enviados para formalização do contrato.
        </p>
      </div>

      <ProposalContractMissionCard />

      {missionDialog.status === "IDLE" && (
        <div className="grid gap-2">
          <p className="text-xl font-bold text-neutral-dark-3">
            O que acontece agora?
          </p>
          <p className="text-base font-medium text-neutral-dark-2">
            Aguarde a formalização. Poderão haver pendências para seguir com o
            contrato e por isso enviaremos notificações para seus contatos
            cadastrados em{" "}
            <Link
              onClick={() => {
                navigate(PATH_APP.account.root);
              }}
            >
              minha conta
            </Link>
            .
          </p>
        </div>
      )}
    </Dialog>
  );
}

export default memo(ProposalContractDialog);
