import { lazy } from "@verde/utils";
import AuthProtect from "components/Auth/AuthProtect";
import { LayoutDefault } from "layouts/Default";
import { PATH_APP } from "routes/paths";
import { Loadable } from "utils/routes";

import Context from "./context";

const AreaFraming = Loadable(lazy(() => import("./pages/area-framing")));
const Client = Loadable(lazy(() => import("./pages/client")));
const Production = Loadable(lazy(() => import("./pages/production")));
const Warranties = Loadable(lazy(() => import("./pages/warranties")));
const Resume = Loadable(lazy(() => import("./pages/resume")));

const workflowContractRoutes = {
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      element: <Context />,
      children: [
        {
          path: PATH_APP.workflowContract.cliente,
          element: <Client />,
        },
        {
          path: PATH_APP.workflowContract.areaFraming,
          element: <AreaFraming />,
        },
        { path: PATH_APP.workflowContract.production, element: <Production /> },
        {
          path: PATH_APP.workflowContract.warranties,
          element: <Warranties />,
        },
        {
          path: PATH_APP.workflowContract.resume,
          element: <Resume />,
        },
      ],
    },
  ],
};

export default workflowContractRoutes;
