import { useAuthContext } from "@verde/modules";
import accountRoutes from "modules/account/account.routes";
import authRoutes from "modules/auth/auth.routes";
import clientsRoutes from "modules/clients/clients.routes";
import dashboardRoutes from "modules/dashboard/dashboard.routes";
import { useMissionsContext } from "modules/missions/context";
import onboardingRoutes from "modules/onboarding/onboarding.routes";
import proposalsRoutes from "modules/proposals/proposals.routes";
import raioXruralRoutes from "modules/raio-x-rural/raio-x-rural.routes";
import simulatorRoutes from "modules/simulator/simulator.routes";
import workflowClientRoutes from "modules/workflow-client/workflow-client.routes";
import workflowContractRoutes from "modules/workflow-contract/workflow-contract.routes";
import workflowProposalRoutes from "modules/workflow-proposal/workflow-proposal.routes";
import NotFound from "pages/NotFound";
import { Navigate, useRoutes } from "react-router-dom";

export default function Router() {
  const { user } = useAuthContext();
  const { isRestricted } = useMissionsContext();

  let routes = [
    authRoutes,
    dashboardRoutes,
    accountRoutes,
    clientsRoutes(),
    raioXruralRoutes,
    { path: "404", element: <NotFound /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ];

  if (user?.kind !== "SAAS_USER") {
    routes = [
      ...routes,
      onboardingRoutes,
      !isRestricted && simulatorRoutes,
      proposalsRoutes,
      workflowProposalRoutes,
      workflowContractRoutes,
      workflowClientRoutes,
    ];
  }

  return useRoutes(routes);
}
