import { Heading } from "@verde/ui-core";
import Progress from "./progress";
import { WizardProps } from "./types";

export default function Wizard({
  title,
  children,
  footer,
  steps,
  pathParams,
  isDone,
}: WizardProps) {
  return (
    <div className="grid gap-4 rounded bg-neutral-light-pure p-4">
      <Heading as="h4">{title}</Heading>

      {children}

      <div className="grid gap-2">
        <Progress isDone={isDone} steps={steps} pathParams={pathParams} />
      </div>

      {footer}
    </div>
  );
}
