import type { Advisor } from "@verde/entities";
import { useAuthContext } from "@verde/modules";
import { lazy } from "@verde/utils";
import AuthProtect from "components/Auth/AuthProtect";
import { LayoutDefault } from "layouts/Default";
import clientsRoutes from "modules/account/account.routes";

import React from "react";
import { PATH_APP } from "routes/paths";
import { Loadable } from "utils/routes";

const ClientsList = Loadable(lazy(() => import("./context")));
const ClientsDetails = Loadable(lazy(() => import("./pages/default/details")));
const CreditProfile = Loadable(
  lazy(() => import("./pages/default/details/tabs/credit-profile")),
);
const BasicData = Loadable(
  lazy(() => import("./pages/default/details/tabs/basic-data")),
);
const Productions = Loadable(
  lazy(() => import("./pages/default/details/tabs/productions")),
);
const Properties = Loadable(
  lazy(() => import("./pages/default/details/tabs/properties")),
);
const ProductionPartners = Loadable(
  lazy(() => import("./pages/default/details/tabs/production-partners")),
);
const Documents = Loadable(
  lazy(() => import("./pages/default/details/tabs/documents")),
);

const clientsDefaultRoutes = {
  path: PATH_APP.clients.default.root,
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.clients.default.root,
      element: <ClientsList />,
    },
    {
      path: PATH_APP.clients.default.details,
      element: <ClientsDetails />,
      children: [
        {
          path: PATH_APP.clients.default.details,
          element: <CreditProfile />,
        },
        {
          path: PATH_APP.clients.default.basicData,
          element: <BasicData />,
        },
        {
          path: PATH_APP.clients.default.productions,
          element: <Productions />,
        },
        {
          path: PATH_APP.clients.default.properties,
          element: <Properties />,
        },
        {
          path: PATH_APP.clients.default.productionPartners,
          element: <ProductionPartners />,
        },
        {
          path: PATH_APP.clients.default.documents,
          element: <Documents />,
        },
      ],
    },
  ],
};

const clientSaasRoutes = {
  path: PATH_APP.clients.saas.root,
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.clients.default.root,
      element: <ClientsList />,
    },
  ],
};

export default function clientRoutes() {
  const { user } = useAuthContext<Advisor>();

  if (user?.kind === "SAAS_USER") return clientSaasRoutes;
  return clientsDefaultRoutes;
}
