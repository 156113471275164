import { type ReactNode, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PATH_APP } from "routes/paths";

import { useWorkflowProposalState } from ".";

interface WorkflowProposalMiddlewareProps {
  children: ReactNode;
}

export default function WorkflowProposalMiddleware({
  children,
}: WorkflowProposalMiddlewareProps) {
  const navigate = useNavigate();
  const { proposal, routesMatcher, replacePathWithParams } =
    useWorkflowProposalState();

  const [isLoaded, setIsLoaded] = useState(false);

  const handleProposalStartFrom = useCallback(() => {
    const progress = proposal.data?.progressable;
    if (!progress?.steps_done.includes("about_financing")) {
      navigate(replacePathWithParams(PATH_APP.workflowProposal.financing));
      setIsLoaded(true);
      return;
    }

    if (!progress?.steps_done.includes("economic_partners")) {
      navigate(replacePathWithParams(PATH_APP.workflowProposal.people));
      setIsLoaded(true);
      return;
    }

    if (
      !progress?.steps_done.includes("harvests") ||
      !progress?.steps_done.includes("source_of_income")
    ) {
      navigate(replacePathWithParams(PATH_APP.workflowProposal.incomeSources));
      setIsLoaded(true);
      return;
    }

    navigate(replacePathWithParams(PATH_APP.workflowProposal.checkout));
    setIsLoaded(true);
  }, [proposal.data, navigate, replacePathWithParams]);

  const handleRedirect = useCallback(() => {
    if (proposal.data?.active_state?.step === "CONSOLIDATION") {
      navigate(replacePathWithParams(PATH_APP.workflowContract.cliente));
      setIsLoaded(true);
      return;
    }

    handleProposalStartFrom();

    if (proposal.data?.active_state?.step !== "DRAFT") {
      navigate(replacePathWithParams(PATH_APP.proposals.details));
      setIsLoaded(true);
      return;
    }

    setIsLoaded(true);
  }, [proposal.data, navigate, replacePathWithParams, handleProposalStartFrom]);

  useEffect(() => {
    if (
      !isLoaded &&
      !routesMatcher.homepage &&
      !proposal.isLoading &&
      process.env.REACT_APP_ENVIRONMENT &&
      ["production"].includes(process.env.REACT_APP_ENVIRONMENT)
    ) {
      handleRedirect();
    } else {
      setIsLoaded(true);
    }
  }, [proposal.isLoading, isLoaded, handleRedirect, routesMatcher]);

  if (!isLoaded) {
    return <p>carregando...</p>;
  }

  return <>{children}</>;
}
