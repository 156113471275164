import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    padding-bottom: ${({ theme }) => theme.space.xlg};
  `}
`;

export const Footer = styled.footer`
  position: relative;

  height: 60px;

  background-image: url("/static/brand/pattern.svg");
  background-repeat: repeat-x;
  background-position: top right;
  background-size: 62px;
`;
