import { Progress } from "@verde/ui-core";
import type { ProgressProps } from "@verde/ui-core";
import { memo } from "react";
import { useMissionsContext } from "../context";

interface ProgressBarProps extends ProgressProps {
  size?: "small" | "large";
}

/**
 * Seria interessante centralizar toda a lógica do de/para de progresso nesse componente,
 * pegando sempre o prazo da missão atual, ou seja, ela vai se mutando conforme a missão atual.
 */
function ProgressBar({ size = "large", ...props }: ProgressBarProps) {
  const { activeMission, missionDailyProgress, missionProgressBarKind } =
    useMissionsContext();

  return (
    <Progress
      kind={missionProgressBarKind}
      value={missionDailyProgress}
      {...props}
      {...(size === "large" && {
        label: "Prazo para missão",
        progress: activeMission?.deadline_days,
      })}
    />
  );
}

export default memo(ProgressBar);
