import type { RatingSituation } from "@verde/entities";
import { motion } from "framer-motion";
import { memo } from "react";
import { tv } from "tailwind-variants";

interface GoalStepsProps {
  title?: string;
  total?: number;
  filter?: string;
  data?: Array<RatingSituation | "NEUTRAL">;
}

const styles = tv({
  slots: {
    base: "flex items-center gap-2 justify-between",
    item: "w-full h-2 rounded-full hover:scale-110 cursor-pointer duration-default",
  },
  variants: {
    kind: {
      default: { item: "bg-neutral-light-2" },
      QUALIFIED: { item: "bg-brand-green-light" },
      DISQUALIFIED: { item: "bg-feedback-error" },
      WITH_OBSERVATION: { item: "bg-brand-yellow" },
      NEUTRAL: { item: "bg-neutral-dark-2" },
    },
  },
});

const animate = {
  container: {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  },
  item: {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  },
};

function GoalSteps({
  title = "Progresso",
  total = 10,
  data = [],
  filter,
}: GoalStepsProps) {
  return (
    <div className="grid gap-2 rounded border border-neutral-light-3 p-4">
      <div className="flex items-center justify-between">
        <p className="text-base font-medium text-neutral-dark-3">{title}</p>

        <p className="text-base font-medium text-neutral-dark-3">
          {data.filter((data) => (filter ? data === filter : true)).length}
          {!filter && `/${total}`}
        </p>
      </div>

      <motion.div
        className={styles().base()}
        variants={animate.container}
        initial="hidden"
        animate="show"
      >
        {Array.from(Array(total).keys()).map((index) => (
          <motion.div
            key={`mission-progress-bar-item-${index}`}
            className={styles().item({
              kind: data?.[index] || "default",
            })}
            variants={animate.item}
          />
        ))}
      </motion.div>
    </div>
  );
}

export default memo(GoalSteps);
