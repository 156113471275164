import { lazy } from "@verde/utils";
import AuthProtect from "components/Auth/AuthProtect";
import { LayoutDefault } from "layouts/Default";
import { PATH_APP } from "routes/paths";
import { Loadable } from "utils/routes";

import Context from "./context";

const Search = Loadable(lazy(() => import("./pages/index")));

const screeningRoutes = {
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      element: <Context />,
      children: [
        {
          path: PATH_APP.raioXrural.search,
          element: <Search />,
        },
        {
          path: PATH_APP.raioXrural.details,
          element: <Search />,
        },
      ],
    },
  ],
};

export default screeningRoutes;
