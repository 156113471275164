import { lazy } from "@verde/utils";
import AuthProtect from "components/Auth/AuthProtect";
import { LayoutDefault } from "layouts/Default";
import React from "react";
import { PATH_APP } from "routes/paths";
import { Loadable } from "utils/routes";

const Simulator = Loadable(lazy(() => import("./pages/Details")));

const simulatorRoutes = {
  path: PATH_APP.simulador.root,
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.simulador.root,
      element: <Simulator />,
    },
  ],
};

export default simulatorRoutes;
