import { CardPreview } from "@verde/compose-ui";
import {
  AlertCircleIcon,
  DocumentIcon,
  ExternalLinkIcon,
  LightIcon,
} from "@verde/icons";
import { memo, useMemo } from "react";

import {
  type Advisor,
  type SecondMissionInfo,
  useFetchSecondMissionInfo,
} from "@verde/entities";
import { useAuthContext } from "@verde/modules";
import { Alert, Text } from "@verde/ui-core";
import { MissionCard } from ".";
import { useMissionsContext } from "../context";
import GoalSteps from "./goal-steps";
import type { MissionCardProps } from "./mission-card";

interface ProposalMissionCardProps extends Partial<MissionCardProps> {}

function ProposalMissionCard({
  children,
  onClick,
  ...props
}: ProposalMissionCardProps) {
  const { user } = useAuthContext<Advisor>();
  const { activeMission, missionDialog, isRestricted } = useMissionsContext<
    "SUCCESS" | "WARNING" | "INCOMPLETE" | "TIMEOUT",
    SecondMissionInfo
  >();

  const mission = useFetchSecondMissionInfo(user?.id);

  const lastProposal = useMemo(() => mission.data?.proposals.at(-1), [mission]);

  if (
    missionDialog.open !== "monitor" &&
    (missionDialog.status === "WARNING" ||
      missionDialog.status === "INCOMPLETE")
  ) {
    return (
      <div className="space-y-6">
        <GoalSteps
          title="Propostas enviadas"
          total={mission.data?.proposal_limit}
          data={new Array(mission.data?.proposals_count).fill("NEUTRAL")}
        />

        <hr />

        <div className="space-y-2">
          <Text className="font-bold">O que fazer agora?</Text>
          <ul className="list-disc font-semibold">
            <li>
              Ainda é possível salvar tudo o que foi cadastrado como rascunho
              para enviar no futuro.
            </li>
            <li>
              Certifique-se de obter a aprovação completa das propostas que
              estão em análise para avançar para a formalização.
            </li>
          </ul>
        </div>

        <Alert
          icon={<LightIcon />}
          classNames={{
            base: "border items-center border-brand-yellow bg-neutral-light-pure",
            icon: "w-6 h-6",
            content: "font-semibold",
          }}
        >
          {" "}
          Após o primeiro desembolso o envio de proposta é ilimitado.
        </Alert>
      </div>
    );
  }

  return (
    <MissionCard
      title="2ª Missão: Envio da primeira proposta"
      subtitle={
        missionDialog.status !== "SUCCESS"
          ? missionDialog.open === "monitor"
            ? "Enviar uma proposta para análise."
            : `Enviar pelo menos uma proposta para análise em até ${activeMission.deadline_days}. Você tem até 5 tentativas.`
          : undefined
      }
      description={
        missionDialog.status !== "SUCCESS"
          ? `Enviar pelo menos uma proposta para análise em até ${activeMission.deadline_days}.`
          : undefined
      }
      {...(["SUCCESS", "TIMEOUT"].includes(missionDialog.status) && {
        size: "large",
      })}
      {...(["SUCCESS", "TIMEOUT"].includes(missionDialog.status) && {
        tag: {
          label: {
            SUCCESS: "Completa!",
            WARNING: "Completa!",
            INCOMPLETE: "Incompleta",
            TIMEOUT: "Prazo encerrado",
          }[missionDialog.status],
          kind:
            missionDialog.status === "SUCCESS" ||
            missionDialog.missions?.[2] !== "SUCCESS"
              ? "success"
              : "error",
        },
      })}
      {...props}
      onClick={
        isRestricted || missionDialog.missions?.[2] === "SUCCESS"
          ? undefined
          : onClick
      }
    >
      {missionDialog.status === "SUCCESS" && (
        <CardPreview
          icon={<DocumentIcon />}
          kind="checked"
          variant="outlined"
          title={`Proposta #${lastProposal?.id}`}
          description={lastProposal?.situation}
          actions={{
            primary: {
              icon: <ExternalLinkIcon />,
              onClick: () => window.open(`/propostas/${lastProposal?.id}`),
            },
          }}
        />
      )}

      <GoalSteps
        title="Propostas enviadas"
        total={mission.data?.proposal_limit}
        data={new Array(mission.data?.proposals_count).fill("NEUTRAL")}
      />

      {!mission.isLoading &&
        !mission.isError &&
        mission.data?.status === "SUCCESS" &&
        activeMission.step === 2 && (
          <Alert
            className="items-center"
            icon={<AlertCircleIcon />}
            kind="info"
            bordered
          >
            Novas missões serão liberadas quando pelo menos uma das propostas
            for aprovada.
          </Alert>
        )}
      {children}
    </MissionCard>
  );
}

export default memo(ProposalMissionCard);
