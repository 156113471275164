import { memo } from "react";

import { type Advisor, useFetchFirstMissionInfo } from "@verde/entities";
import { useAuthContext } from "@verde/modules";
import { MissionCard } from ".";
import { useMissionsContext } from "../context";
import GoalSteps from "./goal-steps";
import type { MissionCardProps } from "./mission-card";

interface ProducerMissionCardProps extends Partial<MissionCardProps> {}

function ProducerMissionCard({
  children,
  onClick,
  ...props
}: ProducerMissionCardProps) {
  const { user } = useAuthContext<Advisor>();
  const { missionDialog, isRestricted } = useMissionsContext<
    | "IDLE"
    | "SUCCESS"
    | "INCOMPLETE"
    | "TIMEOUT"
    | "DISQUALIFIED"
    | "WITH_OBSERVATION"
  >();

  const mission = useFetchFirstMissionInfo(user?.id);

  if (missionDialog.status === "INCOMPLETE") {
    return (
      <GoalSteps
        total={mission.data?.clients_limit}
        data={mission.data?.clients
          ?.sort((client, lastClient) => client.id - lastClient.id)
          .map(({ situation }) => situation)}
      />
    );
  }

  return (
    <MissionCard
      title="1ª Missão: Qualificação de produtores"
      subtitle={
        missionDialog.status === "SUCCESS"
          ? "Cadastre até um produtor qualificado."
          : "Cadastre até um produtor qualificado. Você tem até 10 tentativas!"
      }
      description={
        missionDialog.status !== "SUCCESS"
          ? "Entenda suas chances consultando as possibilidades de crédito de seu cliente."
          : undefined
      }
      {...(missionDialog.status !== "IDLE" && {
        size: "large",
      })}
      {...(["SUCCESS", "INCOMPLETE", "TIMEOUT"].includes(
        missionDialog.status,
      ) && {
        tag: {
          label: {
            SUCCESS: "Completa",
            INCOMPLETE: "Incompleto",
            TIMEOUT: "Prazo encerrado",
            IDLE: "",
            DISQUALIFIED: "",
            WITH_OBSERVATION: "",
          }[missionDialog.status],
          kind:
            missionDialog.status === "SUCCESS" ||
            missionDialog.missions?.[1] === "SUCCESS"
              ? "success"
              : "error",
        },
      })}
      {...props}
      onClick={
        isRestricted || missionDialog.missions?.[1] === "SUCCESS"
          ? undefined
          : onClick
      }
    >
      {missionDialog.status !== "TIMEOUT" && (
        <GoalSteps
          total={mission.data?.clients_limit}
          data={mission.data?.clients
            ?.sort((client, lastClient) => client.id - lastClient.id)
            .map(({ situation }) => situation)}
        />
      )}

      {children}
    </MissionCard>
  );
}

export default memo(ProducerMissionCard);
