import { memo, useEffect, useState } from "react";
import { Spinner } from "@verde/ui-core";
import { Dialog, DialogProps } from "@verde/compose-ui";

export interface FaqDialogProps extends Pick<DialogProps, "open" | "onClose"> {
  home?: keyof typeof LINKS;
}

const LINKS = {
  default: "/Guias-para-o-Assessor-430b32909d064f22933821f1013abb14",
  producer: "/Guia-Perfil-do-Produtor-Rural-bf0977a70b4f4af7a996b5fc8ea43d7d",
  workflow: "/Guia-Cadastro-Completo-a6234981437441f9857f9a7fa4fb44a7",
  "workflow-1": "1-Cadastro-Proposta-ac378a3b2d4040d1ae35306a15d38416",
  "workflow-1-documents":
    "Documentos-para-1-Cadastro-d9acec8610bf4419b2085c7a265595c5",
  "workflow-2": "2-Cadastro-Contrata-o-6eb43a0e7c914cc48299e60313cb2351",
  "workflow-2-documents":
    "Documentos-para-2-Cadastro-ae5bc8bf87b04130a341e67970a83e14",
  credit: "/Guia-Processo-de-Cr-dito-7a8200fc20374e4c80bca50cfa99e3bf",
} as const;

function FaqDialog({ open = true, home = "default", onClose }: FaqDialogProps) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!open) {
      setLoaded(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      size="lg"
      classNames={{
        body: "p-0 rounded overflow-hidden h-[90vh] relative",
      }}
    >
      {!loaded && (
        <div className="top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute grid gap-4">
          <Spinner size="lg" />
          <p className="text-sm font-normal">Aguarde, carregando...</p>
        </div>
      )}

      <iframe
        title="Guias para o Assessor"
        src={`https://v2-embednotion.com/verdeagrbr${LINKS[home]}`}
        className="w-full h-[90vh] relative"
        onLoad={() => {
          setLoaded(true);
        }}
      />
    </Dialog>
  );
}

export default memo(FaqDialog);
