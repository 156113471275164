import { zodResolver } from "@hookform/resolvers/zod";
import { Dialog } from "@verde/compose-ui";
import {
  type Advisor,
  useCreateInterestedAdvisors,
  useFetchCitiesFromState,
  useFetchStates,
} from "@verde/entities";
import { AdvisorIcon, SendIcon } from "@verde/icons";
import { useAuthContext } from "@verde/modules";
import { Alert, FormControl, Select } from "@verde/ui-core";
import { useAlert, z } from "@verde/utils";
import type { AxiosError } from "axios";
import { memo, useCallback, useEffect } from "react";
import { Controller, type FieldErrors, useForm } from "react-hook-form";
import { create } from "zustand";
import type { UseInterestedAdvisorForm } from "./types";

export const useInterestedAdvisorForm = create<UseInterestedAdvisorForm>(
  (set) => {
    return {
      open: false,
      toggle: () => {
        set((state) => ({ open: !state.open }));
      },
    };
  },
);

const currentWorkAlone = z.object({
  current_work: z.literal("ALONE"),
});

const currentWorkBase = z.object({
  current_work: z.enum(["WITH_BANK", "WITH_CAPITAL_MARKET", "WITH_INDUSTRY"]),
  activity_time: z.string(),
  average_disbursement: z.string(),
});

const schema = z
  .object({
    state_id: z.number(),
    city_id: z.number(),
    current_work: z.string(),
    has_current_work: z.boolean(),
    activity_time: z.string(),
    average_disbursement: z.string(),
  })
  .and(
    z.discriminatedUnion("current_work", [currentWorkAlone, currentWorkBase]),
  );
type Schema = z.infer<typeof schema>;
type CurrentWorkBase = z.infer<typeof currentWorkBase>;

function InterestedAdvisorForm() {
  const { open, toggle } = useInterestedAdvisorForm();
  const alert = useAlert();

  const {
    control,
    formState: { errors },
    reset,
    watch,
    handleSubmit,
    setValue,
  } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const stateIdValue = watch("state_id");
  const currentWorkValue = watch("current_work");

  const states = useFetchStates();
  const cities = useFetchCitiesFromState(stateIdValue);
  const { mutateAsync, isLoading } = useCreateInterestedAdvisors();
  const { user } = useAuthContext<Advisor>();

  const handleOnSubmit = useCallback(
    async ({ has_current_work, ...values }: Schema) => {
      const success =
        ["WITH_BANK"].includes(values.current_work) &&
        ["BETWEEN_2_AND_5_YEARS", "UNDER_2_YEARS"].includes(
          values.activity_time,
        );

      await mutateAsync({
        kind: "SAAS_USER",
        activity_time: values.activity_time,
        average_disbursement: values.average_disbursement,
        current_work: values.current_work,
        city_id: values.city_id,
        state_id: values.state_id,
        name: user?.name || "",
        email: user?.email || "",
        document_number: user?.cpf || "",
        phone_number: user?.mobile || "",
      });

      await alert.execute({
        kind: success ? "success" : "warning",
        title: success
          ? "Dados enviados com sucesso!"
          : "Infelizmente não será possível...",
        hideConfirmButton: true,
        cancelText: "Fechar",
        description: (
          <div className="grid gap-4">
            {success && (
              <p className="text-lg font-semibold text-neutral-dark-2">
                Agradecemos pelo interesse, nosso time irá entrar em contato em
                até 48h.
              </p>
            )}

            {!success && (
              <>
                <Alert
                  size="lg"
                  kind="warning"
                  classNames={{
                    base: "border bg-off-yellow-bg",
                  }}
                >
                  <div className="grid gap-2">
                    <p className="text-xl font-semibold text-neutral-dark-2">
                      Obrigado pelo seu interesse!
                    </p>
                    <p className="text-lg font-medium text-neutral-dark-1">
                      No momento, não estamos entrando em contato com Assessores
                      com seu perfil, mas ficamos à disposição para futuras
                      oportunidades.
                    </p>
                    <p className="text-right">
                      🪴 Atenciosamente, Equipe Verde.
                    </p>
                  </div>
                </Alert>

                <div className="flex items-center justify-between gap-4 rounded bg-brand-green-default p-4">
                  <p className="text-lg font-medium text-neutral-light-pure">
                    Siga a Verde em nossas redes para receber novidades:{" "}
                  </p>

                  <div className="flex h-10 items-center gap-2">
                    {[
                      {
                        title: "Instagram",
                        href: "https://www.instagram.com/verde.agr.br",
                        src: "/static/icons/instagram.png",
                      },
                      {
                        title: "Linkedin",
                        href: "https://www.linkedin.com/company/verde-agr-br",
                        src: "/static/icons/linkedin.png",
                      },
                    ].map(({ title, href, src }) => {
                      return (
                        <a
                          key={`verde-socials-item-${title}`}
                          href={href}
                          target="_blank"
                          rel="noreferrer"
                          className="h-full"
                        >
                          <img
                            alt={`${title} link`}
                            src={src}
                            className="h-full object-contain"
                          />
                        </a>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        ),
      });

      reset({});
      toggle();
    },
    [user, mutateAsync, reset, toggle, alert],
  );

  useEffect(() => {
    if (currentWorkValue) {
      setValue("has_current_work", currentWorkValue !== "ALONE");
    }
  }, [currentWorkValue, setValue]);

  return (
    <Dialog
      open={open}
      size="sm"
      onClose={toggle}
      header={{
        size: "lg",
        icon: <AdvisorIcon />,
        title: "Assessor Agro parceiro da Verde",
      }}
      context={<form onSubmit={handleSubmit(handleOnSubmit)} />}
      footer={{
        primary: !user?.asked_to_be_advisor
          ? {
              loading: isLoading,
              icon: <SendIcon />,
              children: "Enviar dados",
              type: "submit",
            }
          : undefined,
        secondary: {
          children: "Fechar",
          onClick: toggle,
        },
      }}
    >
      {!user?.asked_to_be_advisor && (
        <>
          <p className="mb-6 text-lg font-semibold">
            Preencha e envie os dados abaixo:
          </p>

          <div className="grid gap-6">
            <div className="grid gap-2 lg:grid-cols-2">
              <FormControl
                id="state_id"
                label="Estado de atuação"
                kind={errors.state_id && "error"}
                helper={errors.state_id?.message}
              >
                <Controller
                  control={control}
                  name="state_id"
                  render={({ field }) => (
                    <Select
                      {...field}
                      loading={states.isLoading}
                      options={states.data?.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))}
                    />
                  )}
                />
              </FormControl>

              <FormControl
                id="city_id"
                label="Cidade de atuação"
                kind={errors.city_id && "error"}
                helper={errors.city_id?.message}
              >
                <Controller
                  control={control}
                  name="city_id"
                  render={({ field }) => (
                    <Select
                      {...field}
                      loading={cities.isLoading}
                      options={cities.data?.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))}
                      disabled={!stateIdValue}
                    />
                  )}
                />
              </FormControl>
            </div>

            <FormControl
              id="current_work"
              label="Atua com crédito rural atualmente?"
              kind={errors.current_work && "error"}
              helper={errors.current_work?.message}
            >
              <Controller
                control={control}
                name="current_work"
                render={({ field }) => (
                  <Select
                    {...field}
                    options={[
                      {
                        value: "WITH_BANK",
                        label: "Sim, com bancos ou cooperativas ",
                      },
                      {
                        value: "WITH_CAPITAL_MARKET",
                        label: "Sim, com mercado de capitais ou fintechs",
                      },
                      {
                        value: "WITH_INDUSTRY",
                        label: "Sim, com revendas ou indústrias insumos",
                      },
                      {
                        value: "ALONE",
                        label: "Não atuo",
                      },
                    ]}
                  />
                )}
              />
            </FormControl>

            {(() => {
              if (currentWorkValue !== "ALONE") {
                const currentWorkErrors =
                  errors as FieldErrors<CurrentWorkBase>;

                return (
                  <>
                    <FormControl
                      id="activity_time"
                      label="Há quanto tempo atua com crédito rural?"
                      kind={currentWorkErrors.activity_time && "error"}
                      helper={currentWorkErrors.activity_time?.message}
                    >
                      <Controller
                        control={control}
                        name="activity_time"
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={[
                              {
                                value: "OVER_5_YEARS",
                                label: "Menos de 2 anos de experiência",
                              },
                              {
                                value: "BETWEEN_2_AND_5_YEARS",
                                label: "Entre 2 anos e 5 anos de experiência",
                              },
                              {
                                value: "UNDER_2_YEARS",
                                label: "Acima de 5 anos experiência",
                              },
                            ]}
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl
                      id="average_disbursement"
                      label="Valor médio desembolsado por ano em operações?"
                      kind={currentWorkErrors.average_disbursement && "error"}
                      helper={currentWorkErrors.average_disbursement?.message}
                    >
                      <Controller
                        control={control}
                        name="average_disbursement"
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={[
                              {
                                value: "0_TO_10",
                                label: "Menos de R$10 milhões ",
                              },
                              {
                                value: "10_TO_100",
                                label: "Entre R$10 milhões e R$100 milhões",
                              },
                              {
                                value: "101_OR_MORE",
                                label: "Acima de R$100 milhões",
                              },
                            ]}
                          />
                        )}
                      />
                    </FormControl>
                  </>
                );
              }
            })()}
          </div>
        </>
      )}

      {user?.asked_to_be_advisor && (
        <div>
          <p className="text-lg font-semibold text-neutral-dark-2">
            Agradecemos pelo interesse, nosso time irá entrar em contato em até
            48h.
          </p>
        </div>
      )}
    </Dialog>
  );
}

export default memo(InterestedAdvisorForm);
