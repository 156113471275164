import { ErrorBoundary } from "@highlight-run/react";
import { ToastProvider } from "@verde/utils";
import Router from "routes";

export default function App() {
  return (
    <ErrorBoundary
      dialogOptions={{
        title: "Ocorreu um erro",
        subtitle: "Nos perdoe o incômodo já estamos monitorando o que ocorreu.",
        subtitle2: "Deseja nos enviar um feedback sobre o ocorrido?",
        labelName: "Nome",
        labelEmail: "E-mail",
        labelComments: "Mensagem",
        labelClose: "Cancelar",
        labelSubmit: "Enviar",
        successMessage: "Feedback enviado com sucesso.",
        hideHighlightBranding: true,
        placeholderComments: "Descreva aqui o feedback...",
        successSubtitle:
          "Obrigado por nos ajudar a entender melhor o problema, estamos trabalhando para não ocorrer novamente casos como este.",
      }}
    >
      <ToastProvider />
      <div className="prose">
        <Router />
      </div>
    </ErrorBoundary>
  );
}
