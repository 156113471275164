import { tv } from "tailwind-variants";
import { NavLink, useLocation } from "react-router-dom";
import { Heading, Text } from "@verde/ui-core";
import { CheckIcon } from "@verde/icons";

import { useMemo } from "react";
import { ProgressProps, Steps } from "./types";

export const progressStyles = tv({
  slots: {
    base: "grid pl-2",
    item: "group relative grid select-none gap-2 rounded p-4 last:border-none hover:bg-neutral-light-1",
    divider:
      "absolute bottom-0 left-1/2 h-[1px] w-11/12 -translate-x-1/2 bg-neutral-light-3 group-last:hidden",
    line: "absolute left-0 bg-neutral-light-3 top-0 h-full w-[1px] translate-y-5 group-last:hidden duration-default",
    bullet:
      "absolute -left-[3px] top-0 h-[8px] w-[8px] translate-y-5 rounded-full bg-neutral-dark-3",
  },
  variants: {
    isCompleted: {
      true: {
        line: "bg-neutral-dark-3",
        bullet:
          "data-[alldone=true]:bg-neutral-light-3 bg-neutral-dark-3 text-neutral-light-pure w-[11px] h-[11px] -left-[5px]",
      },
    },
    isActive: {
      true: {
        line: "bg-neutral-light-3",
        bullet: "data-[alldone=true]:bg-neutral-dark-3",
      },
    },
  },
  compoundVariants: [
    {
      isActive: false,
      isCompleted: false,
      className: {
        item: "cursor-not-allowed opacity-50 hover:bg-inherit",
      },
    },
  ],
});

export default function Progress({
  steps,
  isDone,
  pathParams,
  allDone,
}: ProgressProps) {
  const location = useLocation();

  const currentStepIndexByPath = useMemo(() => {
    return steps.findIndex(({ path }) => {
      const pathParamsIndexes: number[] = [];

      const splittedPath = path.split("/").filter((route, index) => {
        route.includes(":") && pathParamsIndexes.push(index);

        return !route.includes(":");
      });

      const currentPath = location.pathname
        .split("/")
        .filter((_, index) => !pathParamsIndexes.includes(index));

      return currentPath.join("/") === splittedPath.join("/");
    });
  }, [location.pathname, steps]);

  const replacePathWithParams = (path: Steps["path"]) => {
    if (!pathParams) return path;

    return path
      .split("/")
      .map((route) => {
        if (route.includes(":")) {
          const key = route.replace(":", "");

          return pathParams[key] || route;
        }

        return route;
      })
      .join("/");
  };

  return (
    <div className={progressStyles().base()}>
      {steps.map(({ path, title, description }, index) => {
        const isCompleted = isDone || index < currentStepIndexByPath;

        return (
          <NavLink
            to={replacePathWithParams(path)}
            key={`progress-link-item-${index}`}
            className={({ isActive }) =>
              progressStyles().item({ isActive, isCompleted })
            }
            {...(!isCompleted && {
              onClick: (event) => event.preventDefault(),
            })}
          >
            {({ isActive }) => {
              return (
                <>
                  <Heading as="h2">{title}</Heading>
                  <Text className="text-neutral-dark-1">{description}</Text>

                  <div className={progressStyles().divider()} />
                  <div
                    className={progressStyles().line({ isActive, isCompleted })}
                  />
                  <div
                    data-alldone={allDone}
                    className={progressStyles().bullet({
                      isActive,
                      isCompleted,
                    })}
                  >
                    {<CheckIcon className="stroke-2" />}
                  </div>
                </>
              );
            }}
          </NavLink>
        );
      })}
    </div>
  );
}
