import { PlayIcon } from "@verde/icons";
import { IconButton, Tag, type TagProps } from "@verde/ui-core";
import { type ReactNode, memo, useState } from "react";
import { tv } from "tailwind-variants";

export interface MissionCardProps {
  title: string;
  subtitle?: string;
  description?: string;
  children?: ReactNode;
  size?: "small" | "large";
  tag?: TagProps;
  defaultExpanded?: boolean;
  onClick?: () => void;
}

const styles = tv({
  slots: {
    base: "rounded border border-neutral-light-3 flex flex-col h-fit cursor-pointer hover:bg-neutral-light-1",
    header: "p-4 flex items-center justify-between gap-2 select-none",
  },
  variants: {
    expanded: {
      true: {
        base: "cursor-auto hover:bg-neutral-light-pure",
      },
    },
  },
});

function MissionCard({
  title,
  tag,
  subtitle,
  description,
  children,
  size = "small",
  defaultExpanded = true,
  onClick,
}: MissionCardProps) {
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <div
      className={styles().base({
        expanded,
      })}
    >
      <div
        className={styles().header()}
        onClick={() => {
          setExpanded((prev) => !prev);
        }}
      >
        <p className="text-lg font-semibold line-clamp-1 text-neutral-dark-3">
          {title}
        </p>

        {tag && size === "large" && <Tag size="sm" {...tag} />}
      </div>

      {expanded && (
        <div className="grid gap-4 px-4 pb-4">
          {(subtitle || description) && (
            <div className="flex items-center gap-4 p-4 rounded bg-neutral-light-1">
              <div className="grid gap-1">
                <p className="text-lg font-semibold text-neutral-dark-3">
                  {subtitle}
                </p>

                {size === "large" && (
                  <p className="text-sm font-medium text-neutral-dark-3">
                    {description}
                  </p>
                )}
              </div>

              {onClick && size === "large" && (
                <IconButton onClick={onClick}>
                  <PlayIcon />
                </IconButton>
              )}
            </div>
          )}

          {children}
        </div>
      )}
    </div>
  );
}

export default memo(MissionCard);
