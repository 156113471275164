import { lazy } from "@verde/utils";
import AuthProtect from "components/Auth/AuthProtect";
import { LayoutDefault } from "layouts/Default";
import React from "react";
import { PATH_APP } from "routes/paths";
import { Loadable } from "utils/routes";

const ProposalsList = Loadable(lazy(() => import("./pages/list")));
const ProposalsDetails = Loadable(lazy(() => import("./pages/details")));
const Resume = Loadable(lazy(() => import("./pages/details/tabs/resume")));
const Productions = Loadable(
  lazy(() => import("./pages/details/tabs/productions")),
);
const Properties = Loadable(
  lazy(() => import("./pages/details/tabs/properties")),
);
const ProductionPartners = Loadable(
  lazy(() => import("./pages/details/tabs/production-partners")),
);
const Warranties = Loadable(
  lazy(() => import("./pages/details/tabs/warranties")),
);

const proposalRoutes = {
  path: PATH_APP.proposals.root,
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.proposals.root,
      element: <ProposalsList />,
    },
    {
      path: PATH_APP.proposals.details,
      element: <ProposalsDetails />,
      children: [
        {
          path: PATH_APP.proposals.details,
          element: <Resume />,
        },
        {
          path: PATH_APP.proposals.productions,
          element: <Productions />,
        },
        {
          path: PATH_APP.proposals.properties,
          element: <Properties />,
        },
        {
          path: PATH_APP.proposals.productionPartners,
          element: <ProductionPartners />,
        },
        {
          path: PATH_APP.proposals.warranties,
          element: <Warranties />,
        },
      ],
    },
  ],
};

export default proposalRoutes;
