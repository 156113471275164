import { Dialog, type DialogProps, LabeledDivider } from "@verde/compose-ui";
import { useFetchResultMission } from "@verde/entities";
import {
  AgricultureIcon,
  CashIcon,
  DocumentIcon,
  ExternalLinkIcon,
} from "@verde/icons";
import { useAuthContext } from "@verde/modules";
import { Alert, IconButton, Tag } from "@verde/ui-core";
import { dayjs, formatDate, formatToBRL } from "@verde/utils";
import { createElement, memo, useCallback } from "react";
import { useMissionsContext } from "../context";

interface ProposalDisbursedDialogProps extends Pick<DialogProps, "open"> {}

function ProposalDisbursedDialog({ open }: ProposalDisbursedDialogProps) {
  const { user } = useAuthContext();
  const { closeMissionDialog } = useMissionsContext();
  const mission = useFetchResultMission(user?.id);

  if (mission.status !== "success") return null;

  return (
    <Dialog
      open={open}
      size="sm"
      header={{
        title: (
          <div className="flex items-center gap-2 text-brand-green-dark">
            {createElement(CashIcon, {
              className: "w-6 h-6",
            })}

            <h2 className="text-2xl font-semibold">
              Primeiro desembolso realizado!
            </h2>
          </div>
        ),
        kind: "success",
      }}
      footer={{
        secondary: {
          children: "Fechar",
          onClick: closeMissionDialog,
        },
        children: (
          <>
            <Alert
              kind="success"
              classNames={{ base: "border border-brand-green-light" }}
            >
              <div className="flex items-center gap-4">
                <AgricultureIcon className="h-10 w-10" />
                <p>
                  Agora você pode cadastrar novos clientes e enviar quantas
                  propostas quiser! É ilimitado, por isso lembre-se: quanto mais
                  melhor ;)
                </p>
              </div>
            </Alert>
          </>
        ),
      }}
      classNames={{
        body: "flex flex-col gap-6",
      }}
    >
      <div className="grid gap-4 rounded border border-brand-green-light p-4">
        <div className="flex items-center justify-between gap-2">
          <p className="text-xl text-neutral-dark-2">
            Seus ganhos com operação:
          </p>
          <Tag
            kind="success"
            label={formatToBRL(mission.data?.operation_incomes)}
          />
        </div>

        <div className="grid gap-2">
          {[
            {
              key: "advisor-tax",
              title: `Taxa de assessoramento ${mission.data?.advisor_fee}%`,
              value: formatToBRL(mission.data?.advisor_fee_value),
              description: `Deve ser pago pelo produtor e enviado para você em até 4 dias úteis, em ${formatDate(
                mission.data.disbursed_date,
              )}.`,
            },
            {
              key: "bonus",
              title: `Bônus por adimplência* | ${dayjs(
                mission.data.expiration_date,
              ).format("MMMM [de] YYYY")}`,
              value: `* ${formatToBRL(mission.data?.compliance_bonus_value)}`,
              description:
                "* Bonificação paga após liquidação da operação, com pagamento confirmado até data de vencimento.",
            },
          ].map(({ key, title, value, description }) => (
            <div
              key={`disburse-values-item-${key}`}
              className="grid gap-2 rounded border border-neutral-light-3 p-4"
            >
              <LabeledDivider>
                <p className="text-lg text-neutral-dark-3">{title}</p>

                <p className="text-lg text-neutral-dark-3">{value}</p>
              </LabeledDivider>

              <p className="text-base font-normal text-neutral-dark-1">
                {description}
              </p>
            </div>
          ))}
        </div>

        <div className="flex items-center gap-2 rounded border border-neutral-light-3 p-2">
          <DocumentIcon className="h-3 w-3" />

          <p className="text-base font-normal text-neutral-dark-3">
            Operação #{mission.data?.proposal_id} |{" "}
            {formatToBRL(mission.data.final_value)} - desembolsado
          </p>

          <IconButton
            className="ml-auto"
            size="sm"
            onClick={() =>
              window.open(`/propostas/${mission.data?.proposal_id}`)
            }
          >
            <ExternalLinkIcon />
          </IconButton>
        </div>
      </div>
    </Dialog>
  );
}

export default memo(ProposalDisbursedDialog);
