import React, { ReactNode } from "react";
import { useAuthContext } from "@verde/modules";
import { Navigate } from "react-router-dom";
import { PATH_APP } from "routes/paths";
import { IAdvisor } from "@verde/entities";

type GuestProtectProps = {
  children: ReactNode;
};

export default function GuestProtect({ children }: GuestProtectProps) {
  const { isAuthenticated } = useAuthContext<IAdvisor>();

  if (isAuthenticated) {
    return <Navigate to={PATH_APP.root} />;
  }

  return <>{children}</>;
}
