export function Start() {
  return (
    <div className="grid gap-4">
      <p className="text-neutral-dark-2 text-xl font-bold">
        Estamos felizes com sua chegada.
      </p>

      <div className="rounded p-4 border border-neutral-light-3">
        <p className="text-lg font-semibold text-neutral-dark-2">
          Nosso compromisso é ajudar você a ter acesso a crédito de forma rápida
          e com clientes cada vez mais qualificados! Por isso, criamos
          passo-a-passo com missões que irão levar você o mais rápido possível
          ao primeiro desembolso.
        </p>
      </div>
    </div>
  );
}
