import { HeaderNavigation } from "@verde/compose-ui";
import {
  type Advisor,
  VERDE_PRIVACY_POLICY,
  VERDE_TERMS_OF_USAGE,
  VERDE_WHATSAPP_LINK,
} from "@verde/entities";
import {
  AdvisorIcon,
  BellIcon,
  BuildingIcon,
  CalculatorIcon,
  CardBankIcon,
  DashboardIcon,
  DocumentClipboardIcon,
  FarmProducerIcon,
  KeyIcon,
  LogoutIcon,
  UserIcon,
} from "@verde/icons";
import { Notification, useAuthContext } from "@verde/modules";
import { Button, Ping } from "@verde/ui-core";
import { useInterestedAdvisorForm } from "components/interested-advisor-form";
import { Missions } from "modules/missions";
import { useMissionsContext } from "modules/missions/context";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export function Header() {
  const navigate = useNavigate();
  const { user, logout } = useAuthContext<Advisor>();
  const { isRestricted } = useMissionsContext();
  const {
    unreadNotificationsCount,
    toggleNotificationsDrawer,
    notificationPopupAnchorPoint,
  } = Notification.useNotifications();
  const interestedAdvisorForm = useInterestedAdvisorForm();

  const makeBreadcrumb = useMemo(() => {
    return [
      {
        label: "Plataforma Verde",
        action: () => navigate("/dashboard"),
      },
    ];
  }, [navigate]);

  const makeMenu = useMemo(() => {
    let menu = [
      {
        children:
          user?.kind === "SAAS_USER"
            ? "Dashboard plataforma Verde"
            : "Dashboard",
        icon: <DashboardIcon />,
        onClick: () => navigate("/dashboard"),
      },
      {
        children: "Raio-X rural",
        icon: (
          <img
            alt="Logotipo Raio-X rural"
            src="/static/brand/raio-x-rural-icon.svg"
            className="h-4 w-4 object-contain"
          />
        ),
        onClick: () => navigate("/raio-x-rural"),
      },
      {
        children:
          user?.kind === "SAAS_USER"
            ? "Últimas consultas (lista produtores)"
            : "Produtores",
        icon: <FarmProducerIcon />,
        onClick: () => navigate("/clientes"),
      },
    ];

    if (user?.kind !== "SAAS_USER") {
      menu = [
        ...menu,
        {
          children: "Operações",
          icon: <DocumentClipboardIcon />,
          onClick: () => navigate("/propostas"),
        },
        ...(isRestricted
          ? []
          : [
              {
                children: "Simulador de crédito",
                icon: <CalculatorIcon />,
                onClick: () => navigate("/simulador"),
              },
            ]),
      ];
    }

    return [
      ...menu,
      {
        children: "Termos de Uso",
        onClick: () => window.open(VERDE_TERMS_OF_USAGE, "_blank"),
        className: "text-base font-normal",
      },
      {
        children: "Políticas de privacidade",
        onClick: () => window.open(VERDE_PRIVACY_POLICY, "_blank"),
        className: "text-base font-normal",
      },
    ];
  }, [user, isRestricted, navigate]);

  const makeActions = useMemo(() => {
    if (user?.kind === "SAAS_USER") return;

    return [
      {
        onClick: toggleNotificationsDrawer,
        children: (
          <Ping
            data-hide={unreadNotificationsCount === 0}
            className="group"
            classNames={{ anchor: "group-data-[hide=true]:hidden" }}
          >
            {notificationPopupAnchorPoint(<BellIcon />)}
          </Ping>
        ),
      },
    ];
  }, [
    user,
    toggleNotificationsDrawer,
    unreadNotificationsCount,
    notificationPopupAnchorPoint,
  ]);

  const makeAccount = useMemo(() => {
    const makeMenu = () => {
      let menu = [
        {
          children: "Dados pessoais",
          icon: <UserIcon />,
          onClick: () => navigate("/minha-conta"),
        },
        {
          children: "Trocar senha",
          icon: <KeyIcon />,
          onClick: () => navigate("/minha-conta?tab=password"),
        },
      ];

      if (user?.kind !== "SAAS_USER") {
        menu = [
          ...menu,
          {
            children: "Minha empresa",
            icon: <BuildingIcon />,
            onClick: () => navigate("/minha-conta?tab=business"),
          },
          {
            children: "Dados bancários",
            icon: <CardBankIcon />,
            onClick: () => navigate("/minha-conta?tab=bank-account"),
          },
        ];
      }

      return menu;
    };

    return {
      avatar: user?.image?.file_url,
      name: user?.name,
      menu: makeMenu(),
      content:
        user?.kind === "SAAS_USER" ? (
          <div className="grid gap-4 bg-off-green px-6 py-4">
            <div className="grid gap-1">
              <p className="text-lg font-semibold">Seja Assessor Agro Verde</p>
              <p className="text-sm font-normal text-neutral-dark-1">
                Tenha acesso a todos os recursos da plataforma.
              </p>
            </div>

            <Button
              icon={<AdvisorIcon />}
              kind="secondary"
              onClick={() => {
                interestedAdvisorForm.toggle();
              }}
            >
              Quero ser parceiro
            </Button>
          </div>
        ) : undefined,
      footer: (
        <Button
          kind="secondary"
          size="lg"
          icon={<LogoutIcon />}
          onClick={logout}
        >
          Encerrar sessão
        </Button>
      ),
    };
  }, [user, logout, navigate, interestedAdvisorForm]);

  const makeAddon = useMemo(() => {
    if (user?.kind === "SAAS_USER") return;

    return <Missions.StatusMonitor />;
  }, [user]);

  return (
    <HeaderNavigation
      helpLink={VERDE_WHATSAPP_LINK}
      breadcrumb={makeBreadcrumb}
      features={["help-action"]}
      menu={makeMenu}
      actions={makeActions}
      account={makeAccount}
      addon={makeAddon}
    />
  );
}
