import { Dialog, type DialogProps } from "@verde/compose-ui";
import {
  AlertCircleIcon,
  CheckIcon,
  FlagIcon,
  HelloSymbol,
  HomeIcon,
  LightIcon,
  PlayIcon,
  ReturnIcon,
} from "@verde/icons";
import { Alert } from "@verde/ui-core";
import { lazy } from "@verde/utils";
import { createElement, memo } from "react";
import { useNavigate } from "react-router-dom";
import { PATH_APP } from "routes/paths";
import { Loadable } from "utils/routes";
import { ProgressBar } from ".";
import { useMissionsContext } from "../context";

const ProducerMissionCard = Loadable(
  lazy(() => import("./producer-mission-card")),
);

interface ProducerDialogProps extends Pick<DialogProps, "open"> {}

function ProducerDialog({ open }: ProducerDialogProps) {
  const navigate = useNavigate();
  const { missionDialog, closeMissionDialog, startOrAdvanceMission } =
    useMissionsContext();

  return (
    <Dialog
      open={open}
      size="sm"
      header={{
        title: (
          <div className="flex items-center gap-2 text-brand-green-dark">
            {createElement(
              {
                IDLE: FlagIcon,
                SUCCESS: HelloSymbol,
                WITH_OBSERVATION: () => <div />,
                DISQUALIFIED: () => <div />,
                INCOMPLETE: AlertCircleIcon,
                TIMEOUT: AlertCircleIcon,
              }[missionDialog.status],
              {
                className: "w-6 h-6",
              },
            )}

            <h2 className="text-2xl font-semibold">
              {
                {
                  IDLE: "1ª Missão: Qualificação de produtores",
                  SUCCESS: "Parabéns, missão completa!",
                  WITH_OBSERVATION: "Rating do produtor com observações",
                  DISQUALIFIED: "Rating reprovado...",
                  INCOMPLETE: "Não é possível cadastrar",
                  TIMEOUT: "Missão incompleta",
                }[missionDialog.status]
              }
            </h2>
          </div>
        ),
        kind: (
          {
            IDLE: "success",
            SUCCESS: "success",
            WITH_OBSERVATION: "warning",
            DISQUALIFIED: "error",
            INCOMPLETE: "error",
            TIMEOUT: "error",
          } as const
        )[missionDialog.status],
      }}
      footer={{
        ...{
          IDLE: {
            secondary: {
              onClick: closeMissionDialog,
              children: "Começar",
              icon: <PlayIcon />,
            },
          },
          SUCCESS: {
            primary: {
              onClick: startOrAdvanceMission,
              children: "2ª missão: Criar proposta",
              icon: <PlayIcon />,
            },
            secondary: {
              onClick: closeMissionDialog,
              children: "Ver mais detalhes",
            },
          },
          WITH_OBSERVATION: {
            primary: {
              onClick: () => {
                navigate(PATH_APP.workflowClient.search);
                closeMissionDialog();
              },
              children: "Cadastrar outro",
              icon: <ReturnIcon />,
            },
            secondary: {
              onClick: closeMissionDialog,
              children: "Fechar",
            },
          },
          DISQUALIFIED: {
            primary: {
              onClick: () => {
                navigate(PATH_APP.workflowClient.search);
                closeMissionDialog();
              },
              children: "Cadastrar outro",
              icon: <ReturnIcon />,
            },
            secondary: {
              onClick: closeMissionDialog,
              children: "Fechar",
            },
          },
          INCOMPLETE: {
            secondary: {
              onClick: () => {
                navigate(PATH_APP.general.dashboard);
                closeMissionDialog();
              },
              children: "Voltar para tela inicial",
              icon: <HomeIcon />,
            },
          },
          TIMEOUT: {
            secondary: {
              onClick: () => {
                navigate(PATH_APP.general.dashboard);
                closeMissionDialog();
              },
              children: "Fechar",
            },
          },
        }[missionDialog.status],
        children: (
          <>
            {missionDialog.status === "IDLE" && (
              <Alert
                kind="warning"
                classNames={{
                  content: "font-semibold",
                }}
              >
                Se o prazo da missão chegar ao final, não será permitido
                cadastrar novos produtores.
              </Alert>
            )}

            {missionDialog.status === "SUCCESS" && (
              <Alert
                icon={<LightIcon />}
                classNames={{
                  base: "border items-center border-brand-yellow bg-neutral-light-pure",
                  icon: "w-6 h-6",
                  content: "font-semibold",
                }}
              >
                Cadastre mais produtores para aumentar suas chances de
                desembolsar mais rápido.
              </Alert>
            )}

            {["INCOMPLETE", "TIMEOUT"].includes(missionDialog.status) && (
              <Alert
                kind="warning"
                classNames={{
                  content: "font-semibold",
                }}
              >
                Não se preocupe. Entraremos em contato com você o mais breve
                possível
              </Alert>
            )}

            {!["SUCCESS", "TIMEOUT"].includes(missionDialog.status) && (
              <Alert
                icon={<LightIcon />}
                kind="warning"
                bordered
                classNames={{
                  base: "p-4 items-center",
                  content: "font-semibold",
                  icon: "w-8 h-8",
                }}
              >
                Após o primeiro desembolso o cadastro de novos produtores é
                ilimitado.
              </Alert>
            )}
          </>
        ),
      }}
      classNames={{
        body: "flex flex-col gap-6",
      }}
    >
      {missionDialog.status !== "IDLE" && (
        <div className="flex gap-2">
          {missionDialog.status === "SUCCESS" && (
            <CheckIcon className="h-5 w-5 text-brand-green-dark" />
          )}

          <div className="flex flex-col gap-2">
            <p className="text-xl font-bold">
              {
                {
                  SUCCESS: "Produtor qualificado",
                  WITH_OBSERVATION:
                    "Esse produtor não está qualificado pois existem algumas observações em seu rating.",
                  DISQUALIFIED:
                    "Que pena, não foi dessa vez. Esse produtor está reprovado.",
                  INCOMPLETE: "Suas tentativas acabaram.",
                  TIMEOUT:
                    "Que pena, você não cadastrou nenhum  produtor qualificado.",
                }[missionDialog.status]
              }
            </p>

            <p className="text-lg font-bold">
              {
                {
                  SUCCESS: null,
                  WITH_OBSERVATION: null,
                  DISQUALIFIED: null,
                  INCOMPLETE:
                    "Infelizmente não é mais permitido continuar cadastrando novos produtores.",
                  TIMEOUT: null,
                }[missionDialog.status]
              }
            </p>
          </div>
        </div>
      )}

      <ProducerMissionCard>
        {missionDialog.status === "TIMEOUT" && <ProgressBar />}
      </ProducerMissionCard>

      {missionDialog.status === "TIMEOUT" && (
        <div className="grid gap-2">
          <p className="text-xl font-bold text-neutral-dark-3">
            O prazo para concluir sua missão acabou.
          </p>
          <p className="text-lg font-semibold text-neutral-dark-3">
            Infelizmente não será possível continuar cadastrando novos
            produtores.
          </p>
        </div>
      )}
    </Dialog>
  );
}

export default memo(ProducerDialog);
