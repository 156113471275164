import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
  ReactNode,
} from "react";
import {
  Outlet,
  useLocation,
  matchPath,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import { motion } from "framer-motion";
import { tv } from "tailwind-variants";
import { FetchClient, useFetchRatings } from "@verde/entities";

import { PATH_APP } from "routes/paths";
import {
  WorkflowLoader,
  WorkflowLoaderProps,
} from "components/workflow-loader";

import {
  StateContext,
  UpdaterContext,
  TipProps,
  ReplacePathWithParams,
  RoutesMatcher,
  HandleNavigate,
  HandleNavigating,
} from "./types";

export const WorkflowClientStateContext = createContext<
  StateContext | undefined
>(undefined);

export const WorkflowClientUpdaterContext = createContext<
  UpdaterContext | undefined
>(undefined);

export function useWorkflowClientState() {
  const context = useContext(WorkflowClientStateContext);
  if (!context) {
    throw new Error(
      "useWorkflowClientState must be used within WorkflowClientProvider."
    );
  }

  return context;
}

export function useWorkflowClientUpdater() {
  const context = useContext(WorkflowClientUpdaterContext);
  if (!context) {
    throw new Error(
      "useWorkflowClientUpdater must be used within WorkflowClientProvider."
    );
  }

  return context;
}

export const workflowClientStyles = tv({
  slots: {
    base: "grid items-start gap-4 lg:grid-cols-[minmax(auto,_380px)_minmax(auto,_480px)_minmax(auto,_280px)]",
    aside: "top-2 lg:sticky",
    content: "order-3 lg:-order-none",
  },
});

export const animate = {
  page: {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  },
  transition: {
    type: "tween",
    ease: "linear",
    duration: 0.3,
  },
};

const NAVIGATION_MOVEMENT_DELAY = 1000;

export default function WorkflowClientProvider() {
  const contextRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [client, setClient] = useState<FetchClient | undefined>(undefined);
  const [tip, setTip] = useState<TipProps | undefined>(undefined);
  const [leftSide, setLeftSide] = useState<ReactNode | undefined>(undefined);
  const [rightSide, setRightSide] = useState<ReactNode | undefined>(undefined);
  const [navigatingProps, setNavigatingProps] = useState<
    WorkflowLoaderProps | undefined
  >(undefined);

  const rating = useFetchRatings("clients", Number(params["id"]));

  const isNavigating = useMemo(
    () => typeof navigatingProps !== "undefined",
    [navigatingProps]
  );

  const replacePathWithParams: ReplacePathWithParams = useCallback(
    (path) => {
      if (!client?.id || !path) return "";

      return path?.replace(":id", client?.id.toString()) || "";
    },
    [client]
  );

  const routing = useMemo(() => {
    return [
      PATH_APP.workflowClient.search,
      PATH_APP.workflowClient.qualification,
    ];
  }, []);

  const handleLeftSide = useCallback((element) => {
    setLeftSide(element);
  }, []);

  const handleRightSide = useCallback((element) => {
    setRightSide(element);
  }, []);

  const routesMatcher = useMemo((): RoutesMatcher => {
    return {
      search: matchPath(routing[0], location.pathname),
      qualification: matchPath(routing[1], location.pathname),
    };
  }, [location.pathname, routing]);

  const handleTip = useCallback((params?: TipProps) => {
    setTip(undefined);

    if (!params) return;

    setTimeout(() => {
      setTip(params);
    }, 1500);
  }, []);

  const handleNavigate: HandleNavigate = useCallback(
    (direction) => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      const targetIndex = routing.findIndex((route) =>
        matchPath(route, location.pathname)
      );

      const movement = {
        previous: targetIndex - 1,
        next: targetIndex + 1,
      }[direction];

      setTimeout(() => {
        navigate(
          searchParams.get("next") ||
            replacePathWithParams(routing.at(movement))
        );
      }, NAVIGATION_MOVEMENT_DELAY);
    },
    [location.pathname, searchParams, navigate, replacePathWithParams, routing]
  );

  const previousStep = useCallback(() => {
    handleNavigate("previous");
  }, [handleNavigate]);

  const nextStep = useCallback(() => {
    handleNavigate("next");
  }, [handleNavigate]);

  const handleNavigating: HandleNavigating = useCallback((options) => {
    setNavigatingProps(options);
  }, []);

  const handleNewClient = useCallback(() => {
    setClient(undefined);
    navigate(routing[0]);
  }, [navigate, routing]);

  const stateContextValue = useMemo((): StateContext => {
    return {
      tip,
      rating,
      client,
      routesMatcher,
      replacePathWithParams,
    };
  }, [client, rating, replacePathWithParams, routesMatcher, tip]);

  const updaterContextValue = useMemo((): UpdaterContext => {
    return {
      handleTip,
      nextStep,
      setClient,
      previousStep,
      handleNavigating,
      handleLeftSide,
      handleRightSide,
      handleNewClient,
    };
  }, [
    handleTip,
    nextStep,
    previousStep,
    handleNavigating,
    handleLeftSide,
    handleRightSide,
    handleNewClient,
  ]);

  return (
    <WorkflowClientStateContext.Provider value={stateContextValue}>
      <WorkflowClientUpdaterContext.Provider value={updaterContextValue}>
        <div ref={contextRef} className={workflowClientStyles().base()}>
          <div className={workflowClientStyles().aside()}>
            {leftSide !== undefined && (
              <div className="animate-slideInUp">{leftSide}</div>
            )}
          </div>

          <motion.div
            key={location.pathname}
            initial="initial"
            animate="in"
            variants={animate.page}
            transition={animate.transition}
            className={workflowClientStyles().content()}
          >
            {isNavigating ? (
              <WorkflowLoader
                {...navigatingProps}
                onComplete={() => {
                  setNavigatingProps(undefined);
                  navigatingProps?.onComplete?.();
                }}
              />
            ) : (
              <Outlet />
            )}
          </motion.div>

          <div className={workflowClientStyles().aside()}>
            {rightSide !== undefined && (
              <div className="animate-slideInUp">{rightSide}</div>
            )}
          </div>
        </div>
      </WorkflowClientUpdaterContext.Provider>
    </WorkflowClientStateContext.Provider>
  );
}
