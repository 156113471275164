import React from "react";
import styled from 'styled-components'

import { Box, Spinner } from "@verde/ui";

const Container = styled.div`
  display: "flex";
  align-items: "center";
  justify-content: "center";
`

export default function LoadingScreen() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Container>
        <Spinner size="lg" />
      </Container>
    </Box>
  );
}
