import { useNavigate } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/24/outline";

import { Button, Logo, Tooltip, Typography } from "@verde/ui";

import * as S from "./styles";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <S.Container>
      <S.Logo>
        <Logo variant="minimal" />
      </S.Logo>

      <S.Content display="flex" flexDirection="column" alignItems="center">
        <Typography weight="bold" size="xlg">
          Página não encontrada
        </Typography>

        <Typography color="light">
          Não foi possível encontrar a página que você está procurando.
          <br />
          Possivelmente você digitou incorretamente a URL. Certifique-se de
          verificar a ortografia.
        </Typography>

        <Tooltip content="olá :)" options={{ content: { side: "left" } }}>
          <img
            src="/static/brand/capybara-404.jpg"
            alt="Credits for Capybara Vectors by Vecteezy (https://www.vecteezy.com/free-vector/capybara)"
          />
        </Tooltip>

        <Button
          icon={<HomeIcon />}
          onClick={() => navigate("/dashboard")}
          variant="text"
        >
          Voltar ao início
        </Button>
      </S.Content>
    </S.Container>
  );
}
