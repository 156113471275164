import { memo } from "react";
import { AnimatePresence } from "framer-motion";

import { StatusMonitorTrigger, StatusMonitorContent } from ".";
import { useMissionsContext } from "../context";

function MissionStatusMonitor() {
  const { missionDialog, toggleMissionStatusMonitor } = useMissionsContext();

  return (
    <div className="relative hidden lg:flex">
      <StatusMonitorTrigger onClick={toggleMissionStatusMonitor} />

      <AnimatePresence>
        {missionDialog.open === "monitor" && (
          <StatusMonitorContent onClose={toggleMissionStatusMonitor} />
        )}
      </AnimatePresence>
    </div>
  );
}

export default memo(MissionStatusMonitor);
