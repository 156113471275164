import { Card, CardHeader } from "@verde/compose-ui";
import type { Advisor } from "@verde/entities";
import { DocumentSearchIcon, ExternalLinkIcon } from "@verde/icons";
import { useAuthContext } from "@verde/modules";
import { Alert, Button } from "@verde/ui-core";
import { useSaasBlockAlert } from "components/saas-block-alert";

export default function Aside() {
  const { toggle } = useSaasBlockAlert();
  const { user } = useAuthContext<Advisor>();

  return (
    <aside className="grid gap-4">
      <Card>
        <div className="grid gap-2">
          <p className="font-semibold text-neutral-dark-2">
            Busca e análise de produtores rurais
          </p>

          <div className="h-1 w-[56px] rounded-full bg-brand-yellow" />
        </div>

        <div className="rounded bg-neutral-light-1 p-4">
          {[
            "Para consultar os dados de um produtor pela primeira vez é necessário obter a autorização.",
            "Utilize os créditos Verde para consultar ou atualizar os dados.",
          ].map((item, index, items) => {
            return (
              <div
                key={`info-item-${item}`}
                className="grid gap-4 font-semibold"
              >
                <p className="flex gap-1">
                  <span>&bull;</span> {item}
                </p>

                {index < items.length - 1 && (
                  <hr className="mb-4 bg-neutral-light-2" />
                )}
              </div>
            );
          })}
        </div>
      </Card>

      <Card
        size="sm"
        icon={<DocumentSearchIcon />}
        title="Capacidade de pagamento"
      >
        <Alert
          classNames={{
            base: "bg-off-yellow-bg font-medium",
          }}
        >
          Grátis: descubra a capacidade de pagamento de um produtor criando uma
          proposta de financiamento.
        </Alert>

        <Button
          icon={<ExternalLinkIcon />}
          kind="secondary"
          size="lg"
          onClick={() => {
            if (user?.kind === "SAAS_USER") {
              toggle();
            } else {
              window.open("/proposta", "_blank");
            }
          }}
        >
          Criar proposta
        </Button>
      </Card>
    </aside>
  );
}
