import { CardPreview } from "@verde/compose-ui";
import {
  useFetchCropCultivations,
  useFetchHarvests,
  useFetchOneClient,
  useFetchOneProposal,
  useFetchOneRuralActivity,
  useFetchRatings,
} from "@verde/entities";
import { ExternalLinkIcon, UserCircleIcon } from "@verde/icons";
import { Alert, Skeleton, Text } from "@verde/ui-core";
import { formatToCPF, useTranslation } from "@verde/utils";
import { motion } from "framer-motion";
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { tv } from "tailwind-variants";

import Wizard from "components/Wizard";
import {
  WorkflowLoader,
  type WorkflowLoaderProps,
} from "components/workflow-loader";
import { PATH_APP } from "routes/paths";

import Middleware from "./middleware";
import type {
  HandleNavigate,
  HandleNavigating,
  ReplacePathWithParams,
  RouteParams,
  RoutesMatcher,
  StateContext,
  UpdaterContext,
} from "./types";

export const WorkflowProposalStateContext = createContext<
  StateContext | undefined
>(undefined);

export const WorkflowProposalUpdaterContext = createContext<
  UpdaterContext | undefined
>(undefined);

export function useWorkflowProposalState() {
  const context = useContext(WorkflowProposalStateContext);
  if (!context) {
    throw new Error(
      "useWorkflowProposalState must be used within WorkflowProposalProvider.",
    );
  }

  return context;
}

export function useWorkflowProposalUpdater() {
  const context = useContext(WorkflowProposalUpdaterContext);
  if (!context) {
    throw new Error(
      "useWorkflowProposalUpdater must be used within WorkflowProposalProvider.",
    );
  }

  return context;
}

export const workflowProposalStyles = tv({
  slots: {
    base: "grid items-start gap-4 lg:grid-cols-[minmax(auto,_380px)_minmax(auto,_480px)_minmax(auto,_300px)]",
    aside: "grid top-2 lg:sticky gap-4",
    content: "order-3 lg:-order-none",
    rating:
      "text-sm font-medium data-[situation=QUALIFIED]:text-brand-green-default text-brand-yellow data-[situation=DISQUALIFIED]:text-feedback-error",
  },
});

export const animate = {
  page: {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  },
  transition: {
    type: "tween",
    ease: "linear",
    duration: 0.3,
  },
};

const NAVIGATION_MOVEMENT_DELAY = 1000;

export default function WorkflowProposalProvider() {
  const contextRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<RouteParams>();
  const { t } = useTranslation();

  const [leftSide, setLeftSide] = useState<ReactNode | undefined>(undefined);
  const [rightSide, setRightSide] = useState<ReactNode | undefined>(undefined);
  const [isWizardDone, setIsWizardDone] = useState(false);
  const [navigatingProps, setNavigatingProps] = useState<
    WorkflowLoaderProps | undefined
  >(undefined);

  const proposal = useFetchOneProposal(Number(params?.id));
  const client = useFetchOneClient(proposal.data?.client_id);
  const harvests = useFetchHarvests(proposal.data?.client_id);
  const ruralActivity = useFetchOneRuralActivity(proposal.data?.id);
  const cropCultivations = useFetchCropCultivations();
  const rating = useFetchRatings("clients", client.data?.id);

  const isError = useMemo(
    () => proposal.isError || client.isError,
    [client.isError, proposal.isError],
  );
  const isLoading = useMemo(
    () => proposal.isLoading || client.isLoading,
    [client.isLoading, proposal.isLoading],
  );

  const isNavigating = useMemo(
    () => typeof navigatingProps !== "undefined",
    [navigatingProps],
  );

  const replacePathWithParams: ReplacePathWithParams = useCallback(
    (path) => {
      if (!proposal.data?.id || !path) return "";

      return path.replace(":id", proposal.data.id.toString());
    },
    [proposal.data?.id],
  );

  const routing = useMemo(() => {
    return [
      PATH_APP.workflowProposal.root,
      PATH_APP.workflowProposal.financing,
      PATH_APP.workflowProposal.people,
      PATH_APP.workflowProposal.incomeSources,
      PATH_APP.workflowProposal.checkout,
      PATH_APP.workflowProposal.resume,
    ];
  }, []);

  const routesMatcher = useMemo((): RoutesMatcher => {
    return {
      homepage: matchPath(routing[0], location.pathname),
      financing: matchPath(routing[1], location.pathname),
      people: matchPath(routing[3], location.pathname),
      incomeSources: matchPath(routing[2], location.pathname),
      checkout: matchPath(routing[4], location.pathname),
      resume: matchPath(routing[5], location.pathname),
    };
  }, [location.pathname, routing]);

  const handleNavigate: HandleNavigate = useCallback(
    (direction) => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      proposal.refetch();
      ruralActivity.refetch();
      harvests.refetch();

      const targetIndex = routing.findIndex((route) =>
        matchPath(route, location.pathname),
      );

      const movement = {
        previous: targetIndex - 1,
        next: targetIndex + 1,
      }[direction];

      setTimeout(() => {
        navigate(
          searchParams.get("next") ||
            replacePathWithParams(routing.at(movement)),
        );
      }, NAVIGATION_MOVEMENT_DELAY);
    },
    [
      location.pathname,
      searchParams,
      harvests,
      navigate,
      proposal,
      replacePathWithParams,
      routing,
      ruralActivity,
    ],
  );

  const previousStep = useCallback(() => {
    handleNavigate("previous");
  }, [handleNavigate]);

  const nextStep = useCallback(() => {
    handleNavigate("next");
  }, [handleNavigate]);

  const handleLeftSide = useCallback((element) => {
    setLeftSide(element);
  }, []);

  const handleRightSide = useCallback((element) => {
    setRightSide(element);
  }, []);
  const handleNavigating: HandleNavigating = useCallback((options) => {
    setNavigatingProps(options);
  }, []);

  const stateContextValue = useMemo((): StateContext => {
    return {
      client,
      rating,
      proposal,
      harvests,
      cropCultivations,
      ruralActivity,
      routesMatcher,
      replacePathWithParams,
    };
  }, [
    client,
    rating,
    cropCultivations,
    harvests,
    proposal,
    replacePathWithParams,
    routesMatcher,
    ruralActivity,
  ]);

  const updaterContextValue = useMemo((): UpdaterContext => {
    return {
      handleLeftSide,
      handleRightSide,
      nextStep,
      previousStep,
      handleNavigating,
    };
  }, [
    handleLeftSide,
    handleRightSide,
    nextStep,
    previousStep,
    handleNavigating,
  ]);

  useEffect(() => {
    if (routesMatcher.checkout || routesMatcher.resume) {
      setIsWizardDone(true);
    } else {
      setIsWizardDone(false);
    }
  }, [routesMatcher]);

  if (isError) {
    return (
      <Alert kind="error">
        Ocorreu um erro ou proposta não encontrada, tente novamente mais tarde.
      </Alert>
    );
  }

  if (isLoading) {
    return (
      <div className={workflowProposalStyles().base()}>
        <Skeleton height={380} />
        <Skeleton height={380} />
        <Skeleton height={380} />
      </div>
    );
  }

  return (
    <WorkflowProposalStateContext.Provider value={stateContextValue}>
      <WorkflowProposalUpdaterContext.Provider value={updaterContextValue}>
        <Middleware>
          <div ref={contextRef} className={workflowProposalStyles().base()}>
            <div className={workflowProposalStyles().aside()}>
              {isNavigating === false && !routesMatcher.homepage && (
                <Wizard
                  isDone={isWizardDone}
                  title="Cadastro de proposta"
                  pathParams={{ id: proposal.data?.id }}
                  steps={[
                    {
                      title: "Financiamento",
                      description:
                        "Dados sobre o cliente e sua produção beneficiada",
                      path: PATH_APP.workflowProposal.financing,
                    },
                    {
                      title: "Participantes da produção",
                      description:
                        "Dados de entidades envolvidas na atividade rural",
                      path: PATH_APP.workflowProposal.people,
                    },
                    {
                      title: "Produção e renda",
                      description:
                        "Informações sobre renda e capacidade de receita",
                      path: PATH_APP.workflowProposal.incomeSources,
                    },
                  ]}
                >
                  {client.data?.id && !routesMatcher.resume && (
                    <CardPreview
                      icon={<UserCircleIcon />}
                      title={client.data.name}
                      description={
                        <div className="flex text-sm">
                          <Text className="text-sm">
                            {formatToCPF(client.data.document_number)}
                          </Text>
                          &nbsp;|&nbsp;
                          <Text
                            data-situation={client?.data?.rating?.situation}
                            className={workflowProposalStyles().rating()}
                          >
                            {t(client.data.rating.situation)}
                          </Text>
                        </div>
                      }
                      actions={{
                        primary: {
                          icon: <ExternalLinkIcon />,
                          onClick: () =>
                            window.open(`/clientes/${client?.data?.id}`),
                        },
                      }}
                    />
                  )}
                </Wizard>
              )}

              {leftSide}
            </div>

            <motion.div
              key={location.pathname}
              initial="initial"
              animate="in"
              variants={animate.page}
              transition={animate.transition}
              className={workflowProposalStyles().content()}
            >
              {isNavigating ? (
                <WorkflowLoader
                  {...navigatingProps}
                  onComplete={() => {
                    setNavigatingProps(undefined);
                    navigatingProps?.onComplete?.();
                  }}
                />
              ) : (
                <Outlet />
              )}
            </motion.div>

            <div className={workflowProposalStyles().aside()}>{rightSide}</div>
          </div>
        </Middleware>
      </WorkflowProposalUpdaterContext.Provider>
    </WorkflowProposalStateContext.Provider>
  );
}
