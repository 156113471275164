import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "@verde/compose-ui";
import { Loader, Progress } from "@verde/ui-core";

export interface WorkflowLoaderProps {
  to?: string;
  onComplete?: () => void;
}

function WorkflowLoader({ to, onComplete }: WorkflowLoaderProps) {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  const handleProgress = useCallback(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        const newest = prev + Math.floor(Math.random() * 100);

        if (newest >= 100) {
          if (to) navigate(to, { replace: true });
          onComplete?.();

          clearInterval(interval);
          return 100;
        }

        return newest;
      });
    }, 1000);

    return interval;
  }, [navigate, to, onComplete]);

  useEffect(() => {
    const interval = handleProgress();
    return () => clearInterval(interval);
  }, [handleProgress]);

  return (
    <Card
      classNames={{
        base: "py-6",
      }}
    >
      <Loader className="grid place-items-center" />
      <Progress value={progress} />
    </Card>
  );
}

export default memo(WorkflowLoader);
