import { VERDE_WHATSAPP_LINK } from "@verde/entities";
import { ClockIcon, LightIcon, UsersIcon } from "@verde/icons";

export function Tips() {
  return (
    <div className="grid gap-6">
      <p className="text-xl font-bold text-neutral-dark-2">
        Sinta-se a vontade para explorar a plataforma, mas lembre-se:
      </p>

      <div className="grid gap-4">
        <div className="flex items-center gap-2 rounded border border-neutral-light-3 p-4 font-medium">
          <UsersIcon className="h-5 w-5" />

          <p className="text-lg text-neutral-dark-2">
            Até o primeiro desembolso você só poderá cadastrar até{" "}
            <b className="text-neutral-dark-3">10 produtores</b>.
          </p>
        </div>

        <div className="grid gap-4 rounded border border-neutral-light-3 p-4 font-medium">
          <div className="flex items-center gap-2">
            <ClockIcon className="h-5 w-5" />

            <p className="text-lg text-neutral-dark-2">
              Caso o prazo de alguma missão chegar ao final não será permitido
              cadastrar novos clientes ou criar propostas.
            </p>
          </div>

          <div className="grid gap-6 rounded bg-off-yellow p-4 text-center">
            <p>
              Acompanhe o prazo e o número de cadastros no canto superior
              direito aqui da plataforma:
            </p>

            <img
              src="/static/intro/mission-badge-example.png"
              alt=""
              className="mx-auto block w-[80%]"
            />
          </div>
        </div>

        <div className="flex items-center gap-2 rounded border border-brand-yellow p-4 font-medium">
          <LightIcon className="h-5 w-5" />

          <p className="text-lg text-neutral-dark-2">
            <b className="text-neutral-dark-3">Conte conosco!</b> Em caso de
            dúvida, entre em contato com seu{" "}
            <a
              className="font-semibold text-brand-green-dark hover:underline"
              href={VERDE_WHATSAPP_LINK}
            >
              gerente de relacionamento
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
