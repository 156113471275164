import { Card, Dialog } from "@verde/compose-ui";
import { AdvisorIcon, LightIcon, LockClosedIcon } from "@verde/icons";
import { Alert, Button, Text } from "@verde/ui-core";
import { useInterestedAdvisorForm } from "components/interested-advisor-form";
import interestedAdvisorForm from "components/interested-advisor-form/interested-advisor-form";
import { motion } from "framer-motion";
import React, { useMemo, useState } from "react";
import { create } from "zustand";

export const useSaasBlockAlert = create<{ toggle: () => void; open: boolean }>(
  (set) => {
    return {
      toggle: () => set((state) => ({ open: !state.open })),
      open: false,
    };
  },
);

const CAROUSEL_SETTINGS = {
  per_page: 2,
};

const DATA = [
  {
    title: "Acesso completo à Plataforma Verde",
    text: "Explore todos os recursos disponíveis para otimizar seu trabalho.",
  },
  {
    title: "Operações 100% digitais:",
    text: "Reduza burocracias e ganhe agilidade nos processos.",
  },
  {
    title: "Melhor atendimento à sua carteira",
    text: "Conquiste novos clientes e fortaleça relações com os atuais.",
  },
  {
    title: "Suporte especializado",
    text: "Conte com uma equipe pronta para ajudar em todas as etapas.",
  },
];

function SaasBlockAlert() {
  const { toggle, open } = useSaasBlockAlert();
  const [currentPage, setCurrentPage] = useState(0);
  const itemsLength = useMemo(
    () => Math.ceil(DATA.length / CAROUSEL_SETTINGS.per_page),
    [],
  );
  const interestedAdvisorForm = useInterestedAdvisorForm();

  return (
    <Dialog
      open={open}
      onClose={toggle}
      size="sm"
      header={{
        size: "lg",
        title: "Função bloqueada",
        icon: <LockClosedIcon />,
        kind: "warning",
      }}
      footer={{
        primary: {
          onClick: () => interestedAdvisorForm.toggle(),
          icon: <AdvisorIcon />,
          children: "Quero ser parceiro",
        },
        secondary: {
          children: "Fechar",
          onClick: () => toggle(),
        },
      }}
    >
      <div className="grid gap-4">
        <Alert kind="warning">
          Para acessar essa função é necessário se tornar um Assessor parceiro.
        </Alert>
        <hr />
      </div>
      <Card
        classNames={{
          base: "border border-brand-green-light",
        }}
        icon={
          <img
            alt=""
            src="https://verde-assets.s3.sa-east-1.amazonaws.com/images/brand/verde-r.svg"
            className="h-4 w-4"
          />
        }
        title="Vantagens de ser Assessor parceiro Verde:"
      >
        <div className="inline-flex gap-2">
          {DATA.slice(
            currentPage * CAROUSEL_SETTINGS.per_page,
            currentPage * CAROUSEL_SETTINGS.per_page +
              CAROUSEL_SETTINGS.per_page,
          ).map(({ title, text }) => {
            return (
              <motion.div
                key={`capacity-hint-item-${title}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="item-center grid max-h-[91px] w-full max-w-[291px] flex-1 items-center gap-2 rounded border border-neutral-light-2 bg-neutral-light-pure p-3 text-center"
              >
                <div className="grid w-full justify-center rounded bg-neutral-light-1 p-2">
                  <Text className="text-sm font-semibold text-neutral-dark-3">
                    {title}
                  </Text>
                </div>
                <Text className="text-sm font-medium text-neutral-dark-2">
                  {text}
                </Text>
              </motion.div>
            );
          })}
        </div>

        {itemsLength > 1 && (
          <div className="flex items-center justify-center gap-2">
            {new Array(Math.ceil(DATA.length / CAROUSEL_SETTINGS.per_page))
              .fill(undefined)
              .map((_, index) => {
                return (
                  <div
                    key={`slider-dot-item-${Math.random()}`}
                    className={`h-2 w-2 cursor-pointer rounded-full ${currentPage === index ? "bg-neutral-dark-2" : "bg-neutral-light-3"}`.trim()}
                    onClick={() => setCurrentPage(index)}
                  />
                );
              })}
          </div>
        )}
      </Card>
    </Dialog>
  );
}

export default React.memo(SaasBlockAlert);
