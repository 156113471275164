import { Box } from "@verde/ui";
import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  width: 95%;
  margin-inline: auto;
  margin-block: ${({ theme }) => theme.space["3xl"]};
`;

export const Logo = styled.div`
  width: 30px;

  ${media.greaterThan("medium")`
    position: fixed;
    top: 60px;
    left: 60px;
  `}

  ${media.lessThan("medium")`
    margin-bottom: ${({ theme }) => theme.space["3xl"]};
  `}
`;

export const Content = styled(Box)`
  ${({ theme }) => css`
    height: 100%;
    text-align: center;

    img {
      margin-block: ${theme.space["3xl"]};

      mix-blend-mode: multiply;

      &:hover {
        filter: hue-rotate(45deg);
      }

      ${media.greaterThan("medium")`
        width: 20vw;
      `}
    }
  `}
`;
