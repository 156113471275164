import { lazy } from "@verde/utils";
import AuthProtect from "components/Auth/AuthProtect";
import { LayoutDefault } from "layouts/Default";
import React from "react";
import { Navigate } from "react-router-dom";
import { PATH_APP } from "routes/paths";
import { Loadable } from "utils/routes";

const Dashboard = Loadable(lazy(() => import("./context")));

const userRoutes = {
  path: PATH_APP.root,
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.root,
      element: <Navigate to={PATH_APP.general.dashboard} replace />,
    },
    {
      path: PATH_APP.general.dashboard,
      element: <Dashboard />,
    },
  ],
};

export default userRoutes;
