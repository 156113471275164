import {
  type Advisor,
  type ThirdMissionInfo,
  useFetchThirdMissionInfo,
} from "@verde/entities";
import { AlertCircleIcon } from "@verde/icons";
import { useAuthContext } from "@verde/modules";
import { Alert, Text } from "@verde/ui-core";
import { memo, useMemo } from "react";
import { MissionCard } from ".";
import { useMissionsContext } from "../context";
import GoalSteps from "./goal-steps";
import type { MissionCardProps } from "./mission-card";

interface ProposalApprovedMissionCardProps extends Partial<MissionCardProps> {}

function ProposalApprovedMissionCard({
  children,
  onClick,
  ...props
}: ProposalApprovedMissionCardProps) {
  const { user } = useAuthContext<Advisor>();
  const { activeMission, missionDialog, isRestricted } = useMissionsContext();

  const mission = useFetchThirdMissionInfo(user?.id);

  const getColorByActiveState = (
    activeState: ThirdMissionInfo["proposals"][number]["active_state"],
  ) => {
    if (["REJECTED", "DECLINED"].includes(activeState.action))
      return "DISQUALIFIED";

    if (
      ["CONTRACT", "PAYMENT", "MONITORING"].includes(activeState.status) &&
      activeState.step !== "CONSOLIDATION"
    )
      return "QUALIFIED";

    return "WITH_OBSERVATION";
  };

  const inProgress = useMemo(
    () =>
      mission.data?.proposals.filter((proposal) => {
        if (["REJECTED", "DECLINED"].includes(proposal.active_state.action))
          return false;

        if (
          ["PAYMENT", "MONITORING", "CONTRACT"].includes(
            proposal.active_state.status,
          )
        )
          return false;

        return true;
      }).length,
    [mission.data?.proposals],
  );

  const isIncomplete = useMemo(
    () => mission.data?.status === "INCOMPLETE",
    [mission.data],
  );

  return (
    <MissionCard
      title="3ª missão: Aprovação completa"
      subtitle={
        missionDialog.open === "monitor"
          ? "Consiga uma aprovação completa"
          : "Consiga a aprovação completa de 1 proposta em até 60 dias corridos."
      }
      description={
        missionDialog.open === "monitor"
          ? "Você tem até 60 dias corridos e 5 tentativas de obter a aprovação completa de 1 proposta enviada."
          : undefined
      }
      size="large"
      {...props}
      {...(mission.data && {
        tag: {
          label:
            mission.data.status === "TIMEOUT"
              ? "Prazo encerrado"
              : isIncomplete
                ? "Incompleta"
                : inProgress === 0
                  ? "Completa"
                  : `${inProgress} em andamento`,
          kind:
            mission.data.status === "TIMEOUT" || isIncomplete
              ? "error"
              : inProgress === 0
                ? "success"
                : "highlight",
        },
      })}
      onClick={
        isRestricted || missionDialog.missions?.[3] === "SUCCESS"
          ? undefined
          : onClick
      }
    >
      {children}

      {mission.data?.status !== "TIMEOUT" && (
        <GoalSteps
          title="Propostas em aprovação"
          filter="WITH_OBSERVATION"
          total={activeMission.proposal_limit}
          data={mission.data?.proposals?.map((proposal) =>
            getColorByActiveState(proposal.active_state),
          )}
        />
      )}

      {(isIncomplete ||
        ["TIMEOUT", "INCOMPLETE"].includes(mission.data?.status || "IDLE")) && (
        <Alert kind="error" bordered classNames={{ content: "space-y-2" }}>
          <Text className="text-lg font-semibold">
            {mission.data?.status === "TIMEOUT"
              ? "Prazo encerrado"
              : "Tentativas esgotadas"}
          </Text>
          <Text>
            Infelizmente não será possível continuar criando propostas.
          </Text>

          <Alert kind="warning">
            Entre em contato com seu{" "}
            <u className="font-bold">
              <a
                href={
                  user?.responsable_sale?.admin?.phone_number
                    ? `http://wa.me/55${
                        user.responsable_sale.admin.phone_number
                      }?text=${encodeURI(
                        `${user.responsable_sale.admin.name}, preciso de ajuda!`,
                      )}`
                    : undefined
                }
                target="_blank"
                rel="noreferrer"
              >
                gerente de relacionamento
              </a>
            </u>
          </Alert>
        </Alert>
      )}

      {mission.data && mission.data?.proposals_consolidation_count > 0 && (
        <Alert
          className="items-center"
          icon={<AlertCircleIcon />}
          kind="warning"
          bordered
        >
          Existe {mission.data?.proposals_consolidation_count}{" "}
          {mission.data?.proposals_consolidation_count <= 1
            ? "operação"
            : "operações"}
          &nbsp;em consolidação, continue o cadastro e avance para a próxima
          missão.
        </Alert>
      )}
    </MissionCard>
  );
}

export default memo(ProposalApprovedMissionCard);
