import { memo, useMemo } from "react";

import { CardPreview } from "@verde/compose-ui";
import { useFetchFourthMissionInfo } from "@verde/entities";
import { DocumentIcon } from "@verde/icons";
import { useAuthContext } from "@verde/modules";
import { MissionCard } from ".";
import { useMissionsContext } from "../context";
import type { MissionCardProps } from "./mission-card";

interface ProposalContractMissionCardProps extends Partial<MissionCardProps> {}

function ProposalContractMissionCard({
  children,
  onClick,
  ...props
}: ProposalContractMissionCardProps) {
  const { user } = useAuthContext();
  const { missionDialog, isRestricted } = useMissionsContext();

  const mission = useFetchFourthMissionInfo(user?.id);

  const lastProposal = useMemo(
    () =>
      mission.data?.proposals.filter(
        (proposal) => proposal.active_state.step === "FORMALIZATION",
      )?.[0],
    [mission],
  );

  return (
    <MissionCard
      title="4ª missão: Formalização e assinatura de contrato"
      subtitle={
        missionDialog.open === "monitor"
          ? `${mission.data?.proposals_count} ${
              mission.data && mission.data?.proposals_count > 1
                ? "operações sendo formalizadas"
                : "operação sendo formalizada"
            }`
          : ""
      }
      description={
        missionDialog.open === "monitor"
          ? "Fique atento, pendências podem surgir durante a produção do(s) contrato(s)."
          : ""
      }
      size="large"
      tag={{
        label: missionDialog.status !== "SUCCESS" ? "Em andamento" : "Completa",
        kind: missionDialog.status !== "SUCCESS" ? "highlight" : "success",
      }}
      {...props}
      onClick={
        isRestricted || missionDialog.status === "SUCCESS" ? undefined : onClick
      }
    >
      {missionDialog.open !== "monitor" && (
        <CardPreview
          variant="outlined"
          icon={<DocumentIcon />}
          title={`Operacação #${lastProposal?.id}`}
          description={lastProposal?.client}
        />
      )}

      {children}
    </MissionCard>
  );
}

export default memo(ProposalContractMissionCard);
