import { lazy } from "@verde/utils";
import AuthProtect from "components/Auth/AuthProtect";
import { LayoutDefault } from "layouts/Default";
import { PATH_APP } from "routes/paths";
import { Loadable } from "utils/routes";

import Context from "./context";

const Search = Loadable(lazy(() => import("./pages/search")));
const Financing = Loadable(lazy(() => import("./pages/financing")));
const IncomeSources = Loadable(lazy(() => import("./pages/income-sources")));
const People = Loadable(lazy(() => import("./pages/people")));
const Checkout = Loadable(lazy(() => import("./pages/checkout")));
const Resume = Loadable(lazy(() => import("./pages/resume")));

const screeningRoutes = {
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      element: <Context />,
      children: [
        {
          path: PATH_APP.workflowProposal.root,
          element: <Search />,
        },
        {
          path: PATH_APP.workflowProposal.financing,
          element: <Financing />,
        },
        {
          path: PATH_APP.workflowProposal.people,
          element: <People />,
        },
        {
          path: PATH_APP.workflowProposal.incomeSources,
          element: <IncomeSources />,
        },
        {
          path: PATH_APP.workflowProposal.checkout,
          element: <Checkout />,
        },

        {
          path: PATH_APP.workflowProposal.resume,
          element: <Resume />,
        },
      ],
    },
  ],
};

export default screeningRoutes;
