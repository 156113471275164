import type { Advisor } from "@verde/entities";
import { useAuthContext } from "@verde/modules";
import FaqDialog from "components/faq-dialog/faq-dialog";
import { InterestedAdvisorForm } from "components/interested-advisor-form";
import SaasBlockAlert from "components/saas-block-alert";
import { GuideDialog } from "modules/onboarding/components";
import { Fragment, useReducer } from "react";
import { Outlet } from "react-router-dom";
import { create } from "zustand";
import Floating from "./floating";
import { Header } from "./header";
import * as S from "./styles";
import type { UseLayoutDefault } from "./types";

export const useLayoutDefault = create<UseLayoutDefault>((set) => {
  return {
    faq: {
      open: false,
      home: undefined,
    },
    handleOpenFaq: (home) => {
      set(() => ({
        faq: {
          open: true,
          home,
        },
      }));
    },
    handleCloseFaq: () => {
      set(() => ({
        faq: {
          open: false,
          home: undefined,
        },
      }));
    },
  };
});

export function LayoutDefault() {
  const { faq, handleCloseFaq } = useLayoutDefault();
  const { user } = useAuthContext<Advisor>();

  return (
    <Floating>
      <>
        <Header />

        <div className="container min-h-[86vh] py-8">
          <S.Container>
            <Outlet />
          </S.Container>
        </div>

        <S.Footer />

        <GuideDialog />

        {faq.open && <FaqDialog onClose={handleCloseFaq} home={faq.home} />}
        {user?.kind === "SAAS_USER" && (
          <Fragment>
            <SaasBlockAlert />
            <InterestedAdvisorForm />
          </Fragment>
        )}
      </>
    </Floating>
  );
}
