import { Outlet } from "react-router-dom";
import { create } from "zustand";
import Aside from "./aside";

export const useRaioXRural = create();

export default function RaioXRuralContext() {
  return (
    <div className="grid items-start gap-4 lg:grid-cols-[minmax(auto,_280px)_1fr]">
      <Aside />

      <div>
        <Outlet />
      </div>
    </div>
  );
}
